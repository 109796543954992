import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import { List } from '@material-ui/core';

import useGetMember, { GetMemberProps } from 'api/useGetMember';
import { ReactComponent as MenuIcon } from 'assets/icons/systemicons/more_vertical.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/systemicons/print.svg';
import { ReactComponent as History } from 'assets/icons/systemicons/time.svg';
import { Button, IconButton } from 'components/buttons';
import {
  CollaborationAwareEditor,
  type CollaborationAwareEditorProps,
  useCollaboration,
} from 'components/collaboration';
import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import variants from 'components/editor/constants/types/editorVariants';
import { Update } from 'components/editor/types';
import ListItem from 'components/listItem';
import LockedIndicator from 'components/lockedIndicator';
import { useDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import Popover from 'components/popover';
import Text from 'components/text/Text';
import TimeNavigator from 'components/timeNavigator';
import Tooltip from 'components/tooltip';
import DailyNotePrint from 'features/print/DailyNotePrint';
import VersionHistory from 'features/versionHistory';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import useReferenceStabilizer from 'hooks/useReferenceStabilizer';
import { Flex, HStack } from 'layouts/box/Box';
import {
  useAllMembersKeyed,
  useDoubleClickToLockEditor,
  useFeedTickerVisible,
  useUsers,
} from 'store';
import { DailyNote, EditorValue } from 'types';
import clickIfNothingSelected from 'utils/clickIfNothingSelected';
import { timeVariants } from 'utils/planningViews';

import useDailyNoteEditor from './hooks/useDailyNoteEditor';
import useGetUTCDate from './hooks/useGetUTCDate';
import { useHasUpdatedSubscription, useSelectedDailyNoteDate } from './store';

const DailyNoteWrapper = styled('aside')<{ $isTickerVisible: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  inset: 0;
  margin-bottom: ${({ $isTickerVisible }) => ($isTickerVisible ? '40px' : 0)};
`;

const DailyNoteHeader = styled('header')`
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
`;

const DateWrapper = styled('nav')<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`;

const EditorWrapper = styled('main')`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  flex: 1;
  overflow: hidden;
`;

const DateIndicator = styled(Text)<{ $disabled: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

const DailyNoteComponent = () => {
  const { startOfDay } = useDateTimeUtils();
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const { getUTCDateString } = useGetUTCDate();
  const [selectedDate, setSelectedDate] = useSelectedDailyNoteDate();
  const [localDate, setLocalDate] = useState(new Date());
  const [users] = useUsers();
  const { getRelativeDate } = useCustomDateTimeUtils();
  const [, openPicker] = useDatePicker();

  const utcDateString = getUTCDateString(selectedDate);

  const memberParams: GetMemberProps = useReferenceStabilizer({
    mId: 'dailyNote',
    mRefId: utcDateString,
    fetchPolicy: 'network-only',
  });
  const { data, loading, refetch } = useGetMember<DailyNote>(memberParams);
  const [isTickerVisible] = useFeedTickerVisible() as [boolean, (val: boolean) => void];
  const [membersKeyed] = useAllMembersKeyed();
  const [doubleClickToLockEditor] = useDoubleClickToLockEditor();

  const wrapperRef = useRef<HTMLElement | null>(null);
  const [inViewport, setInViewport] = useState(false);
  const [showPrintDialog, setShowPrintDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showHistory, setShowHistory] = useState(false);
  const [hasUpdatedSub, setHasUpdatedSub] = useHasUpdatedSubscription();

  const onOpenPrintDialog = () => {
    setShowPrintDialog(true);
  };

  const onClosePrintDialog = () => {
    setShowPrintDialog(false);
  };

  const onOpenMenu: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onOpenHistory = () => {
    setAnchorEl(null);
    setShowHistory(true);
  };

  const {
    loading: contentLoading,
    content,
    writeLock,
    readLock,
    ownsLockElsewhere,
    isSavingContent,
    isCancelled,
    lockedByUser,
    locking,
    lockedId,
    shouldResetSelection,
    onEditorUpdate,
    onFocusEditor,
    onSavePress,
    onCancelPress,
    beforeunloadFn,
    lockAsync,
    refetchContent,
    onForceUnlock,
    onCheckVersionRestorability,
    cancelDebouncedCallback,
    onRestoreVersion: onRestore,
    addLockReleasedOnSaveHandler,
  } = useDailyNoteEditor(data);

  const collabOptions = {
    writeLock,
    lockedBy: lockedId ?? null,
    locking,
    lockAsync,
  };
  const { editorInfo, lockBarInfo } = useCollaboration(
    'dailyNote',
    inViewport ? utcDateString : undefined,
    collabOptions,
  );

  const beforeUnloadRef = useRef(beforeunloadFn);
  beforeUnloadRef.current = beforeunloadFn;

  const onRestoreVersion = async (newContent: EditorValue) => {
    await onRestore(newContent);
    setShowHistory(false);
  };

  const onTimeNavigatorUpdate = (date: string) => {
    setLocalDate(new Date(date));
    setSelectedDate(startOfDay(new Date(date)));
  };

  const onTimeIndicatorUpdate = useCallback(
    (date: string) => {
      /** receives date without offset */
      /** adjusting offset to get local date */
      const changedDate = new Date(date);
      const offset = new Date().getTimezoneOffset();
      const tempDate = new Date(changedDate.getTime() - offset * 60000);
      setLocalDate(tempDate);
      setSelectedDate(startOfDay(new Date(date)));
    },
    [setSelectedDate, startOfDay],
  );

  const selectDate = useCallback(
    (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      openPicker({
        anchorEl: ev.currentTarget,
        selectRange: false,
        hideUnscheduleButton: true,
        showTimePicker: false,
        startValue: localDate.toISOString(),
        selectDate: ({ startDate }) => onTimeIndicatorUpdate(startDate),
      });
    },
    [localDate, onTimeIndicatorUpdate, openPicker],
  );

  useEffect(() => {
    return addLockReleasedOnSaveHandler((lock) => lockBarInfo.prepareUnlockOnSave(lock));
  }, [lockBarInfo.prepareUnlockOnSave, addLockReleasedOnSaveHandler]);

  useEffect(() => {
    const element = wrapperRef.current;
    const observer = new IntersectionObserver(([entry]) => {
      setInViewport(entry.isIntersecting);
    });

    if (element) {
      observer.observe(element);
    }

    // Cleanup observer on component unmount
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  });

  useLayoutEffect(() => {
    const triggerOnBeforeUnload = (e?: BeforeUnloadEvent) => void beforeUnloadRef.current(e);
    window.addEventListener('beforeunload', triggerOnBeforeUnload);

    return () => {
      triggerOnBeforeUnload();
      window.removeEventListener('beforeunload', triggerOnBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.mRefId, inViewport]);

  useEffect(() => {
    cancelDebouncedCallback();
  }, [data?.mRefId, cancelDebouncedCallback]);

  useEffect(() => {
    if (hasUpdatedSub && inViewport && data) {
      refetchContent();
      setHasUpdatedSub(false);
    } else if (hasUpdatedSub && inViewport && !data) {
      refetch().then(
        () => {},
        () => {},
      );
    }
  }, [data, hasUpdatedSub, inViewport, refetch, refetchContent, setHasUpdatedSub]);

  const editorProps: CollaborationAwareEditorProps = {
    variant: variants.DAILYNOTE,
    collaborationInfo: editorInfo,
    value: content,
    update: onEditorUpdate as Update,
    onSave: onSavePress,
    users,
    isAllowed: true,
    placeholder: 'Type Something...',
    height: '100%',
    reducedHeight: 'calc(100% - 40px)',
    readLock,
    writeLock,
    shouldResetSelection,
  };

  return (
    <DailyNoteWrapper $isTickerVisible={isTickerVisible}>
      <DailyNoteHeader>
        <Tooltip
          title={
            data ? (
              <HStack>
                {membersKeyed[data?.mUpdatedById ?? '']?.mTitle ?? 'Unknown'},
                {` ${isoToLocaleShort(data?.mUpdatedAt)}`}
              </HStack>
            ) : (
              'No Daily Note have been added for this date yet.'
            )
          }
        >
          <Text variant="h7">Daily note</Text>
        </Tooltip>
        <Flex alignItems="center" justifyContent="end">
          <Button
            width="min-content"
            usage="text"
            onClick={onOpenPrintDialog}
            disabled={!data || writeLock}
          >
            <PrintIcon />
            Print
          </Button>
          <IconButton
            variant="contained"
            usage="contained"
            size={24}
            iconSize={16}
            title="More options"
            onClick={onOpenMenu}
          >
            <MenuIcon />
          </IconButton>
        </Flex>
      </DailyNoteHeader>
      <Tooltip title={writeLock ? 'Unsaved changes' : ''}>
        <DateWrapper $disabled={locking}>
          <TimeNavigator
            onChange={onTimeNavigatorUpdate}
            timeVariant={timeVariants.DAY}
            time={localDate}
            disabled={writeLock}
          />
          <DateIndicator
            color={writeLock ? 'disabled' : 'mediumEmphasis'}
            variant="listItemLabelMedium"
            onClick={selectDate}
            $disabled={writeLock}
          >
            {getRelativeDate(new Date(localDate))}
          </DateIndicator>
        </DateWrapper>
      </Tooltip>
      <DebouncedLoadingIndicator
        isLoading={loading || contentLoading || locking}
        debounceTime={locking ? 0 : undefined}
      />
      <EditorWrapper
        ref={wrapperRef}
        onClick={(ev) => {
          if (doubleClickToLockEditor) return;
          const handler = () => onFocusEditor(!editorInfo.collaborationEnabled || ev.altKey);
          clickIfNothingSelected(ev, handler);
        }}
        onDoubleClick={(ev) => {
          if (!doubleClickToLockEditor) return;
          onFocusEditor?.(!editorInfo.collaborationEnabled || ev.altKey).then(
            () => {},
            () => {},
          );
        }}
      >
        <CollaborationAwareEditor {...editorProps} />
        <LockedIndicator
          readLock={readLock}
          writeLock={writeLock}
          isCurrentUser={writeLock || ownsLockElsewhere}
          ownsLockElsewhere={ownsLockElsewhere}
          collaborationInfo={lockBarInfo}
          lockedBy={lockedByUser}
          isSaving={isSavingContent}
          isCancelled={isCancelled}
          onDone={onSavePress}
          onCancel={onCancelPress}
          lockedId={lockedId}
          onForceUnlock={onForceUnlock}
          disableSave={!writeLock || contentLoading || isSavingContent || loading}
        />
      </EditorWrapper>
      {showPrintDialog && data && (
        <DailyNotePrint
          isDialogOpen={true}
          onCloseDialog={onClosePrintDialog}
          dailyNote={data}
          date={selectedDate}
        />
      )}
      <Popover
        onClose={closeMenu}
        anchorEl={anchorEl}
        position="top"
        type="surfaceCardDark"
        noMargin
        style={{ zIndex: 1301 }}
      >
        <List disablePadding style={{ minWidth: '240px' }}>
          <ListItem
            text="History"
            info={undefined}
            icon={<History />}
            onClick={onOpenHistory}
            disabled={!data?.mRefId}
          />
        </List>
      </Popover>
      {showHistory && data?.mRefId && (
        <VersionHistory
          id={data?.mRefId}
          isSavingContent={isSavingContent}
          open={true}
          title={`Daily note for ${localDate.toISOString().slice(0, 10)}`}
          onCancel={() => setShowHistory(false)}
          onOk={onRestoreVersion}
          checkVersionRestorability={onCheckVersionRestorability}
          versionElement={data?.mType}
        />
      )}
    </DailyNoteWrapper>
  );
};

export default DailyNoteComponent;
