import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';

import { useUpdateIntegration } from 'api/config/useUpdateIntegration';
import { useUpdatePrompts } from 'api/config/useUpdatePrompts';
import { useUpdateMdf } from 'api/mdf/useUpdateMdf';
import { useUpdateOptionList } from 'api/optionLists/useUpdateOptionList';
import { useUpdateOrderForm } from 'api/order_forms/useUpdateOrderForm';
import useUpdatePlatformWorkflows from 'api/platform/useUpdatePlatformWorkflows';
import useGetGroupPolicy from 'api/useGetGroupPolicy';
import Dialog from 'components/dialog';
import { revertTree } from 'components/editMdfDialog/utils';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import useToast from 'components/toast/useToast';
import configCtx from 'contexts/configContext';
import UserCtx from 'contexts/UserContext';
import { useGetAllPlatforms } from 'hooks/useGetPlatforms';
import UPDATE_RUNDOWN_SYNC from 'operations/mutations/updateRundownSync';
import { Platform } from 'types';
import { IntegrationEnum, UpdateMemberInput } from 'types/graphqlTypes';

import useUpdatePriorities from './components/settingsTabs/systemSettings/details/priority/useUpdatePriorities';
import useGetAllRundownTemplates from './hooks/useGetAllRundownTemplates';
import useGetLinks from './hooks/useGetLinks';
import useGetMetadataForms from './hooks/useGetMetadataForms';
import useGetPanels from './hooks/useGetPanels';
import useGetSettings from './hooks/useGetSettings';
import useGetWebhooks from './hooks/useGetWebhooks';
import useUpdateEditorCommands from './hooks/useUpdateEditorCommands';
import useUpdateFormMetadataField from './hooks/useUpdateFormMetadataField';
import useUpdateGroup from './hooks/useUpdateGroup';
import { useUpdateLinks } from './hooks/useUpdateLinks';
import { useUpdatePanels } from './hooks/useUpdatePanels';
import { useUpdatePlatform } from './hooks/useUpdatePlatform';
import { useUpdateProvider } from './hooks/useUpdateProvider';
import useUpdateProviderNotifications from './hooks/useUpdateProviderNotifications';
import useUpdateRundownGridViews from './hooks/useUpdateRundownGridViews';
import useUpdateSystemSettings from './hooks/useUpdateSystemSettings';
import useUpdateProfile from './hooks/useUpdateUserProfile';
import useUpdateUserSettings from './hooks/useUpdateUserSettings';
import {
  useChangedInstanceFields,
  useChangedLinkFields,
  useChangedMetadataField,
  useChangedRundownGridViewColumns,
  useChangedRundownGridViews,
  useIsOpenConfirmDialog,
  useNewLinkFieldId,
  useNewMetadataFieldId,
} from './atoms';
import {
  useChangedActions,
  useChangedEditorCommands,
  useChangedGeneralSystemSettings,
  useChangedIntegrationWebhooks,
  useChangedMdfs,
  useChangedOptionLists,
  useChangedOrderForms,
  useChangedPanels,
  useChangedPlatforms,
  useChangedPlatformWorkflowSettings,
  useChangedPlugins,
  useChangedPriorities,
  useChangedProfileDepartments,
  useChangedProfileMetadata,
  useChangedProfilePicture,
  useChangedProfileTeams,
  useChangedPrompts,
  useChangedProviderNotificationSettings,
  useChangedProviders,
  useChangedRows,
  useChangedRundownPermissions,
  useChangedUserSettings,
  useIsPrioritiesChanged,
  usePriorities,
} from './atomsTs';
import Settings from './Settings';

import { LoadingWrapper, SettingsWrapper } from './styled';

const linearPlatform = {
  mId: 'platform',
  mRefId: 'linear',
  mTitle: 'linear',
  platform: 'linear',
  mType: 'platform',
  __typename: 'MemberType',
};

const mappedPlatformValues = (platform: Platform) => {
  const accounts = platform?.mProperties?.accounts ?? [];
  return {
    mId: 'platform',
    mRefId: platform?.mRefId,
    mTitle: platform?.mTitle,
    mType: 'platform',
    platform: platform?.mProperties?.platform,
    accounts,
    workflowSettings: platform?.workflowSettings,
  };
};

const SettingsContainer = ({
  settingOpen,
  setSettingOpen,
}: {
  settingOpen: boolean;
  setSettingOpen: (val: boolean) => void;
}) => {
  const { mId } = useContext(UserCtx);
  const { kanbanBoardStates } = useContext(configCtx);
  const { toast, errorToast } = useToast();
  const { updateMdf } = useUpdateMdf();
  const { updateOptionList } = useUpdateOptionList();
  const { updateIntegration } = useUpdateIntegration();
  const { updateProvider } = useUpdateProvider();
  const { updatePlatform } = useUpdatePlatform();
  const { updatePrompts } = useUpdatePrompts();
  const { updateOrderForm } = useUpdateOrderForm();
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);
  const { groupPolicies, loading } = useGetGroupPolicy();
  const [disabled, setDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [changedRows, setChangedRows] = useChangedRows();
  const [, setIsOpenConfirmDialog] = useIsOpenConfirmDialog();
  const [changedGeneralSystemSettings, setChangedGeneralSystemSettings] =
    useChangedGeneralSystemSettings();
  const [changedMetadataField, setChangedMetadataField] = useChangedMetadataField();
  const [newMetadataFieldId, setNewMetadataFieldId] = useNewMetadataFieldId();
  const [changedLinkFields, setChangedLinkFields] = useChangedLinkFields();
  const [changedEditorCommands, setChangedEditorCommands] = useChangedEditorCommands();
  const [changedMdfs, setChangedMdfs] = useChangedMdfs();
  const [changedOptionLists, setChangedOptionLists] = useChangedOptionLists();
  const [changedOrderForms, setChangedOrderForms] = useChangedOrderForms();
  const [changedActions, setChangedActions] = useChangedActions();
  const [changedPlugins, setChangedPlugins] = useChangedPlugins();
  const [changedWebhook, setChangedWebhook] = useChangedIntegrationWebhooks();
  const [changedPrompts, setChangedPrompts] = useChangedPrompts();
  const [, setNewLinkFieldId] = useNewLinkFieldId();
  const [changedUserSettings, setChangedUserSettings] = useChangedUserSettings();
  const [changedRP, setChangedRP] = useChangedRundownPermissions();
  const [changedProfileMetadata, setChangedProfileMetadata] = useChangedProfileMetadata();
  const [changedProfilePicture, setChangedProfilePicture] = useChangedProfilePicture();
  const [changedProfileTeams, setChangedProfileTeams] = useChangedProfileTeams();
  const [changedProfileDepts, setChangedProfileDepts] = useChangedProfileDepartments();
  const isPrioritiesChanged = useIsPrioritiesChanged();
  const [changedProviderNotificationSettings, setChangedProviderNotificationSettings] =
    useChangedProviderNotificationSettings();
  const { update: updateProviderNotificationSettings } = useUpdateProviderNotifications();
  const [systemSettings, systemSettingsLoading] = useGetSettings(mId);
  const [metadataForms, metadataFormsLoading] = useGetMetadataForms();
  const [updatePermissions, loadingUpdatePermissions] = useUpdateGroup();
  const [updateLinks, loadingLinks] = useUpdateLinks();
  const [updateSystemSettings, loadingUpdateSystemSettings] = useUpdateSystemSettings();
  const [updateMetadataField, loadingUpdateMetadataField] = useUpdateFormMetadataField();
  const [updateUserSettings, loadingUpdateUserSettings] = useUpdateUserSettings();
  const { loading: loadingUpdateEditorCommands, updateEditorCommands } = useUpdateEditorCommands();
  const { updateProfileMetadata, updateProfilePicture, updateProfileTeamsOrDepts } =
    useUpdateProfile();
  const [updateRundownGridViews, loadingUpdateRundownGridViews] = useUpdateRundownGridViews();
  const [updatePanels, loadingUpdatePanels] = useUpdatePanels();
  const [changedInstanceFields, setChangedInstanceFields] = useChangedInstanceFields();
  const [changedRundownGridViews, setChangedRundownGridViews] = useChangedRundownGridViews();
  const [changedRundownGridViewColumns, setChangedRundownGridViewColumns] =
    useChangedRundownGridViewColumns();
  const [priorities, setPriorities] = usePriorities();
  const [changedPriorities, setChangedPriorities] = useChangedPriorities();
  const [changedPanels, setChangedPanels] = useChangedPanels();
  const updatePriorities = useUpdatePriorities();
  const [changedPlatformWorkflow, setChangedPlatformWorkflow] =
    useChangedPlatformWorkflowSettings();
  const { updatePlatformWorkflows } = useUpdatePlatformWorkflows();
  const [changedProviderAtom, setChangedProviderAtom] = useChangedProviders();
  const [changedPlatformAtom, setChangedPlatformAtom] = useChangedPlatforms();

  useEffect(() => {
    setChangedPriorities(priorities);
  }, [priorities, setChangedPriorities]);

  useEffect(() => {
    const hasValidProviderInWebhook = Object.values(changedWebhook).some(
      (hook) => hook.mProvider !== '' && hook.mProvider !== null,
    );
    if (
      changedGeneralSystemSettings.length !== 0 ||
      changedRows.length !== 0 ||
      changedMetadataField.length !== 0 ||
      newMetadataFieldId !== '' ||
      changedInstanceFields.length ||
      changedLinkFields.length ||
      changedEditorCommands.length ||
      Object.values(changedMdfs).length ||
      Object.values(changedOptionLists).length ||
      Object.values(changedOrderForms).length ||
      Object.values(changedActions).length ||
      (Object.values(changedWebhook).length && hasValidProviderInWebhook) ||
      Object.values(changedPlugins).length ||
      changedPrompts?.length ||
      changedRundownGridViews.length !== 0 ||
      changedRundownGridViewColumns.length !== 0 ||
      changedUserSettings.length ||
      changedPanels.length ||
      changedRP.length ||
      changedProfileMetadata ||
      changedProfilePicture ||
      changedProfileTeams ||
      changedProfileDepts ||
      changedProviderNotificationSettings.length ||
      Object.entries(changedPlatformWorkflow).length ||
      isPrioritiesChanged ||
      Object.values(changedProviderAtom).length ||
      Object.values(changedPlatformAtom).length
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    changedGeneralSystemSettings,
    changedRows,
    changedMetadataField,
    newMetadataFieldId,
    changedInstanceFields,
    changedLinkFields,
    changedEditorCommands,
    changedMdfs,
    changedOptionLists,
    changedOrderForms,
    changedActions,
    changedPlugins,
    changedWebhook,
    changedPrompts,
    changedRundownGridViews,
    changedRundownGridViewColumns,
    changedUserSettings,
    changedPanels,
    changedRP,
    changedProfileMetadata,
    changedProfilePicture,
    changedProfileTeams,
    changedProfileDepts,
    changedProviderNotificationSettings,
    isPrioritiesChanged,
    changedPlatformWorkflow,
    changedProviderAtom,
    changedPlatformAtom,
  ]);

  const [links, linksLoading] = useGetLinks();
  const [webhooks, webhooksLoading] = useGetWebhooks();
  const [platforms, platformsLoading] = useGetAllPlatforms();
  const [rundowns, rundownsLoading] = useGetAllRundownTemplates();
  const [panels, panelsLoading] = useGetPanels();

  const platformsData = useMemo(() => {
    return platforms?.getPlatforms.map((p) => mappedPlatformValues(p)) ?? [];
  }, [platforms]);

  const allPlatforms = useMemo(() => {
    if (!platformsData) return [];

    const linearPlatformExistsInData = platformsData?.some((p) => p.mRefId === 'linear');
    return linearPlatformExistsInData ? platformsData : [linearPlatform, ...platformsData];
  }, [platformsData]);

  const onClose = () => {
    setChangedPriorities(priorities);
    setSettingOpen(false);
  };

  const form = useMemo(() => {
    return metadataForms.find((f) => f.mRefId === 'form-00');
  }, [metadataForms]);

  const handleCloseSettings = () => {
    if (!disabled) {
      setIsOpenConfirmDialog(true);
    } else {
      onClose();
    }
  };

  const handlePermissions = () => {
    // @ts-expect-error FIX updatePermissions => TS
    updatePermissions(groupPolicies);
  };

  const discardChanges = () => {
    setIsOpenConfirmDialog(false);
    setChangedGeneralSystemSettings([]);
    setChangedMetadataField([]);
    setChangedRows([]);
    setNewMetadataFieldId('');
    setChangedInstanceFields([]);
    setChangedLinkFields([]);
    setChangedEditorCommands([]);
    setChangedMdfs({});
    setChangedOptionLists({});
    setChangedOrderForms({});
    setChangedActions({});
    setChangedPlugins({});
    setChangedWebhook({});
    setChangedProviderAtom({});
    setChangedPlatformAtom({});
    setChangedPrompts(null);
    setNewLinkFieldId('');
    setChangedRundownGridViews([]);
    setChangedRundownGridViewColumns([]);
    setChangedUserSettings([]);
    setChangedPanels([]);
    setChangedRP([]);
    setChangedProfileMetadata(null);
    setChangedProfilePicture(null);
    setChangedProfileTeams(null);
    setChangedProfileDepts(null);
    setChangedProviderNotificationSettings([]);
    setChangedPlatformWorkflow({});
  };

  const onSave = async () => {
    setIsSaving(true);
    const updates = [];
    if (changedRows.length > 0) {
      handlePermissions();
    }
    if (changedGeneralSystemSettings.length > 0) {
      // @ts-expect-error FIX updateSystemSettings => TS
      updates.push(updateSystemSettings(systemSettings));
    }

    if (changedMetadataField.length > 0 || changedInstanceFields.length > 0) {
      // @ts-expect-error FIX updateMetadataField => TS
      updates.push(updateMetadataField(form, allPlatforms));
    }

    if (changedLinkFields.length) {
      // @ts-expect-error FIX updateLinks => TS
      updates.push(updateLinks());
    }

    if (changedEditorCommands.length) updates.push(updateEditorCommands());

    if (Object.values(changedMdfs).length) {
      for (const changedMdf of Object.values(changedMdfs)) {
        updates.push(updateMdf(changedMdf));
      }
    }

    if (Object.values(changedOptionLists).length) {
      for (const changeInfo of Object.values(changedOptionLists)) {
        if (changeInfo.item.optionListType === 'choice' || !changeInfo.treeOptions) {
          updates.push(updateOptionList(changeInfo.item));
        } else {
          const treeAlternatives = revertTree(changeInfo.treeOptions);
          updates.push(updateOptionList({ ...changeInfo.item, treeAlternatives }));
        }
      }
    }

    if (Object.values(changedOrderForms).length) {
      for (const changedForm of Object.values(changedOrderForms)) {
        updates.push(updateOrderForm(changedForm as UpdateMemberInput));
      }
    }

    if (Object.values(changedActions).length) {
      for (const changedAction of Object.values(changedActions)) {
        await updateIntegration({
          mRefId: changedAction.mRefId,
          integrationType: IntegrationEnum.CustomAction,
          externalId: changedAction.externalId,
          mTitle: changedAction.mTitle,
          auth: changedAction.auth,
          mDescription: changedAction.mDescription,
          mActive: changedAction.mActive,
          mTypes: changedAction.mTypes,
          connectedIds: changedAction.connectedIds,
          endpoint: changedAction.endpoint,
          iconUrl: changedAction.iconUrl,
        });
      }
    }

    if (Object.values(changedPlugins).length) {
      for (const changedPlugin of Object.values(changedPlugins)) {
        await updateIntegration({
          mRefId: changedPlugin.mRefId,
          integrationType: IntegrationEnum.SearchPlugin,
          externalId: changedPlugin.externalId,
          mTitle: changedPlugin.mTitle,
          auth: changedPlugin.auth,
          mDescription: changedPlugin.mDescription,
          mActive: changedPlugin.mActive,
          mTypes: changedPlugin.mTypes,
          connectedIds: changedPlugin.connectedIds,
          endpoint: changedPlugin.endpoint,
          iconUrl: changedPlugin.iconUrl,
        });
      }
    }

    if (Object.values(changedWebhook).length) {
      for (const changedHook of Object.values(changedWebhook)) {
        const integrationType =
          changedHook?.mId == 'endpoint' ? IntegrationEnum.EndPoint : IntegrationEnum.Webhook;

        await updateIntegration({
          mRefId: changedHook.mRefId,
          integrationType: integrationType,
          externalId: changedHook.externalId,
          mTitle: changedHook.mTitle,
          auth: changedHook.auth,
          mDescription: changedHook.mDescription,
          mProvider: changedHook.mProvider,
          mActive: changedHook.mActive,
          mTypes: changedHook.mTypes,
          connectedIds: changedHook.connectedIds,
          endpoint: changedHook.endpoint,
          iconUrl: changedHook.iconUrl,
        });
      }
    }

    if (Object.values(changedProviderAtom).length) {
      for (const changedProvider of Object.values(changedProviderAtom)) {
        await updateProvider(changedProvider);
      }
    }
    if (Object.values(changedPlatformAtom).length) {
      for (const changedPlatform of Object.values(changedPlatformAtom)) {
        await updatePlatform(changedPlatform);
      }
    }

    if (changedPrompts?.length) {
      updates.push(updatePrompts(changedPrompts));
    }

    if (changedRundownGridViews.length > 0 || changedRundownGridViewColumns.length > 0) {
      updates.push(
        // @ts-expect-error FIX updateRundownGridViews => TS
        updateRundownGridViews(form, changedRundownGridViewColumns, changedRundownGridViews),
      );
    }

    if (changedUserSettings.length) {
      // @ts-expect-error FIX updateUserSettings => TS
      updates.push(updateUserSettings(systemSettings));
    }

    if (changedPanels.length) {
      updates.push(updatePanels());
    }

    if (changedRP.length) {
      const promises = changedRP.map((cRP) => {
        const input = {
          ...cRP,
          mPayload: [
            {
              crudAction: 'UPDATE',
            },
          ],
        };
        return updateRundownSync({
          variables: { input },
        });
      });
      await Promise.all(promises);
    }

    if (changedProfileMetadata) {
      updates.push(updateProfileMetadata(mId, changedProfileMetadata));
    }

    if (changedProfilePicture) {
      updates.push(updateProfilePicture(changedProfilePicture));
    }

    if (changedProfileTeams) {
      updates.push(updateProfileTeamsOrDepts(mId, 'team', changedProfileTeams));
    }

    if (changedProfileDepts) {
      updates.push(updateProfileTeamsOrDepts(mId, 'department', changedProfileDepts));
    }

    if (changedProviderNotificationSettings.length) {
      updates.push(updateProviderNotificationSettings());
    }

    if (isPrioritiesChanged) {
      setPriorities(changedPriorities);
      updates.push(updatePriorities(JSON.stringify(changedPriorities)));
    }

    if (Object.entries(changedPlatformWorkflow).length) {
      updates.push(updatePlatformWorkflows({ updatedWorkflows: changedPlatformWorkflow }));
    }

    try {
      await Promise.all(updates).finally(() => setIsSaving(false));
      toast({ title: 'Successfully saved changes', type: 'success' });
      discardChanges();
    } catch (err) {
      errorToast(err);
    }
  };

  const onSaveAndClose = () => {
    setIsOpenConfirmDialog(false);
    onSave()
      .catch(errorToast)
      .finally(() => onClose());
  };

  const onDiscardAndClose = () => {
    discardChanges();
    onClose();
  };

  const onCloseConfirmDialog = () => setIsOpenConfirmDialog(false);

  const loadingDependencies =
    loadingUpdatePermissions ||
    loadingUpdateSystemSettings ||
    loadingUpdateMetadataField ||
    loadingUpdateRundownGridViews ||
    loadingLinks ||
    rundownsLoading ||
    loadingUpdateEditorCommands ||
    loadingUpdateUserSettings ||
    loadingUpdatePanels;

  const bootstrappingDialog =
    loading ||
    systemSettingsLoading ||
    metadataFormsLoading ||
    platformsLoading ||
    linksLoading ||
    webhooksLoading ||
    loadingDependencies ||
    panelsLoading;

  const renderContent = groupPolicies && systemSettings && metadataForms && platforms && panels;

  return (
    <Dialog open={settingOpen} disableEnforceFocus container={undefined} onClose={undefined}>
      <SettingsWrapper id="settings-wrapper">
        {bootstrappingDialog || !renderContent ? (
          <LoadingWrapper>
            <LoadingIndicator />
          </LoadingWrapper>
        ) : (
          <Settings
            groupPolicies={groupPolicies}
            systemSettings={systemSettings}
            form={form}
            links={links}
            webhooks={webhooks}
            onSaveAndClose={onSaveAndClose}
            onDiscardAndClose={onDiscardAndClose}
            onSave={onSave}
            handleCloseSettings={handleCloseSettings}
            instanceTypes={allPlatforms}
            rundowns={rundowns}
            disabled={disabled}
            isSaving={isSaving}
            onCloseConfirmDialog={onCloseConfirmDialog}
            kanbanStates={kanbanBoardStates}
            panels={panels}
          />
        )}
      </SettingsWrapper>
    </Dialog>
  );
};

export default SettingsContainer;
