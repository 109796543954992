import { useQuery } from '@apollo/client';

import useCheckUserRight from 'hooks/useCheckUserRight';
import useSanitizeDates from 'hooks/useSanitizeDates';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'operations/queries/getRundownByPublishingDate';
import { GetMembersByPublishingDateInput, MemberTypeEnum, Rundown } from 'types/graphqlTypes';

interface Props {
  startDate: string;
  endDate: string;
  orgId?: string;
}

type ReturnType = {
  getRundownsByPublishingDate: Rundown[];
};

type InputType = {
  input: GetMembersByPublishingDateInput;
};

const useGetDailyRundowns = ({ startDate, endDate, orgId }: Props) => {
  const { sanitizeDates } = useSanitizeDates();
  const { from, to } = sanitizeDates(startDate, endDate);

  const variables: { input: GetMembersByPublishingDateInput } = {
    input: {
      mType: MemberTypeEnum.Rundown,
      startDate: from,
      endDate: to,
    },
  };

  const [checkUserRight] = useCheckUserRight();
  const canAccessOrganization = checkUserRight('feature', 'organization');

  if (canAccessOrganization && orgId) {
    if (orgId === 'Global') variables.input.globalsOnly = true;
    else {
      variables.input.globalsOnly = false;
      variables.input.orgId = orgId;
    }
  }

  const { data, error, loading } = useQuery<ReturnType, InputType>(
    GET_RUNDOWNS_BY_PUBLISHING_DATE,
    {
      variables,
      fetchPolicy: 'cache-and-network',
    },
  );

  return {
    data: data?.getRundownsByPublishingDate,
    error,
    loading,
  };
};

export default useGetDailyRundowns;
