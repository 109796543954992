import { getDinaSessionId } from 'utils/sessionId';

export const getLockToken = (scope: string) => window.sessionStorage.getItem(scope);

/**
 * Sets a lock token in the session
 * @param scope    The scope that the lock should belong to
 * @param userId   The ID of the user that acquires the lock
 * @param lockTime The lock time. Use an empty string to create an exclusive lock.
 * @returns
 */
export const setLockToken = (scope: string, userId: string, lockTime?: string) => {
  const exclusivity = lockTime === '' ? 'exclusive' : 'collaborative';
  const timeStamp = lockTime ? lockTime : new Date().toISOString();
  const token = `${userId}||${timeStamp}||${scope}||${getDinaSessionId(userId)}||${exclusivity}`;
  window.sessionStorage.setItem(scope, token);

  return token;
};

export const resetLockToken = (scope: string) => {
  window.sessionStorage.removeItem(scope);
};

export const getScopeFromLockedId = (lockedId: string | null) => {
  if (!lockedId) return null;
  return lockedId.split('||')[2];
};

export const getUserIdFromLockedId = (lockedId: string | null) => {
  if (!lockedId) return null;
  return lockedId.split('||')[0];
};

export const getTimestampFromLockedId = (lockedId: string | null) => {
  if (!lockedId) return null;
  return lockedId.split('||')[1];
};

export const getDinaSessionIdFromLockedId = (lockedId: string | null) => {
  if (!lockedId) return null;
  return lockedId.split('||').at(3) ?? '';
};

export const isExclusiveLock = (lockedId: string | null) => {
  return !!lockedId && lockedId.endsWith('||exclusive');
};

/**
 * Checked if a `lock` is used to lock to the current user and a current scope in this session.
 * @param lock   The lock to check
 * @param userId The ID of the current user
 * @param scope  The current scope
 * @returns      `true` if the lock is owned here by the current user, otherwise `false`.
 */
export function isLockedHereByUser(lock: string | null, userId: string, scope: string | undefined) {
  return (
    getUserIdFromLockedId(lock) === userId &&
    getScopeFromLockedId(lock) === scope &&
    getDinaSessionId(userId) === getDinaSessionIdFromLockedId(lock)
  );
}
