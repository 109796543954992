import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as MasterRundownOff } from 'assets/icons/systemicons/HeaderNavbar/master_rundown_off.svg';
import { ReactComponent as More } from 'assets/icons/systemicons/more_vertical.svg';
import { IconButton } from 'components/buttons/IconButton';
import DropdownMenu from 'components/dropdownMenu/DropdownMenu';
import Text from 'components/text';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { HStack } from 'layouts/box/Box';
import { useSelectedOrgId, useSwitchableOrganizations } from 'store';

import { useRundownOrgId } from './store';

const RundownListDropdown = ({
  toggleMasterRundown,
  isMasterRundown,
}: {
  toggleMasterRundown: () => void;
  isMasterRundown: boolean;
}) => {
  const [orgId, setOrgId] = useRundownOrgId();
  const [switchableOrgs] = useSwitchableOrganizations();
  const [selectedOrgId] = useSelectedOrgId();

  const [checkUserRight] = useCheckUserRight();
  const canAccessOrganization = checkUserRight('feature', 'organization');

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <IconButton height={28} width={28} title="More" padding={1} tabIndex={-1}>
          <More />
        </IconButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          {canAccessOrganization && (
            <>
              <DropdownMenu.Header>Show Rundowns from</DropdownMenu.Header>
              <DropdownMenu.RadioGroup value={orgId} onValueChange={(o) => setOrgId(o)}>
                <DropdownMenu.RadioItem value="Global">
                  <HStack width="100%" justifyContent="space-between" flexGrow={1}>
                    <Text variant="listItemLabel" color="highEmphasis">
                      Global
                    </Text>
                  </HStack>
                </DropdownMenu.RadioItem>
                {switchableOrgs.map((org) => (
                  <DropdownMenu.RadioItem key={org.id} value={org.id}>
                    <HStack width="100%" justifyContent="space-between" flexGrow={1}>
                      <Text variant="listItemLabel" color="highEmphasis">
                        {org.title ?? org.id}
                      </Text>
                      {selectedOrgId === org.id && (
                        <Text variant="caption" color="statusWarning">
                          &nbsp;(Active Organization)
                        </Text>
                      )}
                    </HStack>
                  </DropdownMenu.RadioItem>
                ))}
              </DropdownMenu.RadioGroup>
              <DropdownMenu.Separator />
            </>
          )}
          <DropdownMenu.Item onSelect={toggleMasterRundown}>
            {isMasterRundown ? (
              <>
                <Close /> Exit Rundown Templates
              </>
            ) : (
              <>
                <MasterRundownOff /> Edit Rundown Templates
              </>
            )}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
};

export default RundownListDropdown;
