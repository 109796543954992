import styled from '@emotion/styled';

import FloatDefault from 'assets/images/rundown/float/RundownFloat_Default.png';
import FloatSelected from 'assets/images/rundown/float/RundownFloat_Selected.png';

const ROW_HEIGHT = '32px';

export const StyledTable = styled('table')`
  display: grid;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};

  thead {
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
    ${({ theme }) => theme.typography.dina.overline};
    display: grid;
    position: sticky;
    top: 0px;
    z-index: 1;
    tr {
      display: flex;
      position: sticky;
      top: 0;
      border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
      th {
        border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
        padding-inline: 8px;
        user-select: none;
        height: ${ROW_HEIGHT};
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        :focus-within {
          outline: none;
          background: ${({ theme }) => theme.palette.dina.hoverOverlay};
          outline: 1px dashed ${({ theme }) => theme.palette.dina.highEmphasis};
        }
      }
    }
  }

  tbody {
    display: grid;
    position: relative;
    tr {
      box-sizing: border-box;
      display: flex;
      position: absolute;
      width: 100%;
      border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
      :nth-of-type(even) {
        background-color: ${({ theme }) => theme.palette.dina.tableAlternatingRowOverlay};
      }
      td {
        display: flex;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;
        height: ${ROW_HEIGHT};
        border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
        padding-inline: 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        :hover {
          background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
        }
        :empty {
          cursor: not-allowed;
        }
        :focus-within {
          outline-offset: -1px;
          outline: 1px dashed ${({ theme }) => theme.palette.dina.highEmphasis};
        }
        :has(:focus-within) {
          outline: 2px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
          background: ${({ theme }) => theme.palette.dina.statusOnFocusedDiscreetFill};
        }
      }
    }
    .dnd-table-row {
      :nth-of-type(even) {
        background-color: ${({ theme }) => theme.palette.dina.tableAlternatingRowOverlay};
      }
    }
  }
`;

export const StyledTh = styled('th')`
  ${({ theme }) => theme.typography.dina.overline};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  padding-left: 4px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};

  &:hover .dragHandle {
    display: block;
  }
`;

/**
 * Rundown has other styling needs than most other tables,
 *
 * separating out the styles here to not mess with each other.
 */
export const StyledRundownTable = styled('table')`
  display: grid;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};

  tr.rundown thead {
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundMain};
  }

  thead {
    height: 32px;
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
    ${({ theme }) => theme.typography.dina.overline};
    display: grid;
    position: sticky;
    top: 0px;
    z-index: 1;
    tr {
      display: flex;
      position: sticky;
      top: 0;
      border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
      th {
        border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
        user-select: none;
        height: ${ROW_HEIGHT};
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        :focus-within {
          outline: none;
          background: ${({ theme }) => theme.palette.dina.hoverOverlay};
          outline: 1px dashed ${({ theme }) => theme.palette.dina.highEmphasis};
        }
      }
    }
  }

  tbody {
    display: grid;
    position: relative;
    tr {
      box-sizing: border-box;
      display: flex;
      position: absolute;
      width: 100%;
      border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
      td {
        display: flex;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;
        height: ${ROW_HEIGHT};
        border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        :hover {
          background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
        }
        :empty {
          cursor: not-allowed;
        }
        :focus-within {
          outline-offset: -1px;
          outline: 1px dashed ${({ theme }) => theme.palette.dina.highEmphasis};
        }
        :has(:focus-within) {
          outline: 1px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
          background: ${({ theme }) => theme.palette.dina.statusOnFocusedDiscreetFill};
        }
      }
    }
  }

  &.isDragging tr.selected {
    opacity: 0.3 !important;
  }

  .group_start * {
    line-height: 22px !important;
    font-style: italic;
    border-right: none;
    &:hover {
      background: none;
    }
  }

  .group_start {
    border-bottom: none;
    color: #ffffff !important;
    &.darkText * {
      color: #000000 !important;
      fill: #000000 !important;
    }
    .rundown_dragHandle {
      margin-top: -2px;
    }
  }
`;

export const StyledTableRow = styled('tr')`
  position: relative;

  .inline-rundown-wrapper {
    border: 1px solid transparent;
    border-left: none;
    border-right: none;
  }

  &.selected:not(.group_start):not(.group_end) {
    background: ${({ theme }) => theme.palette.dina.onSelected} !important;
    border-bottom-color: rgba(255, 255, 255, 0.3);

    td {
      background: inherit !important;
    }

    &.group_end div {
      background: inherit !important;
    }

    td:has(:focus-within) {
      outline: 1px solid ${({ theme }) => theme.palette.dina.highEmphasis};
      input::placeholder {
        color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
      }
    }

    .inline-rundown-wrapper {
      border-color: ${({ theme }) => theme.palette.dina.highEmphasis};
    }
  }

  &.selected.group_start {
    outline: 1px solid white;
  }

  &.isPlaying {
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 2px red;
      top: 0;
      pointer-events: none;
    }
  }

  &.hasRun {
    opacity: 0.4;
  }

  &.floated {
    background-image: url(${FloatDefault}) !important;
    &.selected:not(.group_start):not(.group_end) {
      background-image: url(${FloatSelected}) !important;
      background-size: 60px !important;
    }
    background-size: 60px !important;
  }

  &.group_start {
    td {
      border-right: 0;
    }
  }

  &.isOver:after {
    content: '';
    height: 2px;
    position: absolute;
    width: 100%;
    background: ${({ theme }) => theme.palette.dina.onFocus};
    bottom: -1px;
  }

  &.isOver.movingUp:after {
    content: '';
    height: 2px;
    position: absolute;
    width: 100%;
    background: ${({ theme }) => theme.palette.dina.onFocus};
    top: 0px !important;
  }
`;

export const SortHandle = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  gap: 4px;
  max-height: 14px;
  overflow: hidden;
  white-space: nowrap;
`;

export const Resizer = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  right: -1.5px;
  width: 5px;
  border-radius: 2px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  :hover {
    background: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &.isResizing {
    background: ${({ theme }) => theme.palette.dina.onFocus};
    opacity: 1;
  }
`;
