import { useRef } from 'react';
import styled from '@emotion/styled';

import sagaGif from 'assets/images/notifications/rundownGroups/DinaNowSaga.gif';
import { ReactComponent as SagaIcon } from 'assets/images/SagaIcon.svg';
import { Button } from 'components/buttons';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import Text from 'components/text/Text';
import { Box, Flex, VStack } from 'layouts/box/Box';
import URLS from 'utils/constants/urls';

type Props = {
  onLogout: () => Promise<void>;
};

const StyledA = styled('a')`
  color: ${({ theme }) => theme.palette.dina.blue};
`;

const versionNumber = (import.meta.env.REACT_APP_VERSION as string) || 'N.NN.N';
const versionDateUtc = (import.meta.env.REACT_APP_VERSION_DATE as string) || new Date().toString();
const versionDate = new Date(versionDateUtc).toLocaleDateString();
const { CHANGELOG_URL } = URLS;

export default function Footer({ onLogout }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <VStack width="100%" padding="8px 8px 16px 8px">
      <Button variant="contained" usage="danger" onClick={() => void onLogout()}>
        Log out
      </Button>
      <VStack padding="16px 8px 8px 8px" width="100%">
        <Text variant="captionSmall">Saga by Saga Media Tech AS</Text>
        <Text
          as="a"
          href={CHANGELOG_URL}
          target="_blank"
          rel="noopener noreferrer"
          variant="captionSmall"
        >
          Version {versionNumber}, {versionDate}
        </Text>

        <Text variant="captionSmall">Copyright © Saga Media Tech AS</Text>
        <Text variant="captionSmall">All rights reserved</Text>
      </VStack>
      <Flex alignSelf="flex-end" margin="-28px 0 0 0" ref={ref}>
        <SagaIcon className="skipOverride" />
      </Flex>
      <NotificationPopup
        useHighlight
        position="left"
        anchor={ref}
        id={notificationIdentifiers.DinaNowSaga}
        imageUrl={sagaGif}
        title="Dina is now Saga"
        text={
          <Box>
            Read all about it in our{' '}
            <StyledA href="https://saganews.com/news/dina-is-saga" target="_blank" rel="noreferrer">
              press release.
            </StyledA>
          </Box>
        }
      />
    </VStack>
  );
}
