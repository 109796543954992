import { ElementRef, memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ScopeProvider } from 'jotai-molecules';
import pipe from 'lodash/fp/pipe';
import isNull from 'lodash/isNull';
import { createEditor, Descendant, Editor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, Slate, withReact } from 'slate-react';

import FallbackComponent from 'components/fallbackComponent';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { useBlockForms } from 'store';
import { CustomEditor, CustomElement, CustomText } from 'types';

import Dialogs from './components/dialogs/Dialogs';
import EmojiCombobox from './components/emojiCombobox';
import HoveringToolbar from './components/hoveringToolbar/view';
import { withLink } from './components/link/utils';
import UserCommands from './components/mdf/components/userCommands';
import Suggestions from './components/mention/components/suggestions';
import Toolbar, { ToolbarProps } from './components/toolbar';
import toolbarPositions from './constants/toolbarPositions';
import { ActionTypesEnum } from './constants/types/actionTypes';
import variants from './constants/types/editorVariants';
import useMosItemReplaceHandler from './hooks/useMosItemReplace';
import { isVoidSelected } from './utils/getSelectedElement';
import isSaveKeyEvent from './utils/isSaveKeyEvent';
import notifyChange from './utils/notifyChange';
import { initialValues } from './constants';
import Editable from './CustomEditable';
import EditorContext from './EditorContext';
import { EditorScope } from './store';
import {
  BaseEditor,
  EditorContextProps,
  EditorProps,
  EditorVariant,
  getDefaultPlaceholderConfigs,
  PlatformSpecificForm,
  UpdateInput,
} from './types';
import {
  withChecklist,
  withInline,
  withNormalization,
  withRestrictDeletion,
  withUppercase,
  withVoid,
} from './utils';

import { EditorWrapper, ToolbarWrapper } from './styled';

const { GENERAL, CMS } = variants;

const isValidDocument = (document: CustomElement[]) => !!document?.length;

const slateEditor = <T extends BaseEditor>(
  variant: EditorVariant,
  customizeEditor: (x: Editor) => T,
) =>
  pipe(
    createEditor,
    customizeEditor,
    withReact,
    withHistory,
    (editor) => withNormalization(editor, variant),
    withVoid,
  );

const wrappedEditor = <T extends BaseEditor>(
  variant: EditorVariant,
  customizeEditor: (x: BaseEditor) => T,
) =>
  pipe(
    slateEditor(variant, customizeEditor),
    withInline,
    withChecklist,
    withLink,
    withUppercase,
    withRestrictDeletion,
  );

const defaultToolbar = ({
  variant,
  readOnly,
  isAllowed,
  platformStructure,
  isCmsBlock,
  toolbarPosition,
  platformKind,
  showDoneButton,
  showSidepanelButton,
  disableGeneralToolbar,
  showHoveringTooltip,
  hostReadSpeed,
  writeLock,
  containerRef,
}: ToolbarProps) => (
  <ToolbarWrapper>
    <Toolbar
      disableGeneralToolbar={disableGeneralToolbar}
      variant={variant}
      readOnly={readOnly}
      isAllowed={isAllowed}
      platformStructure={platformStructure}
      isCmsBlock={isCmsBlock}
      toolbarPosition={toolbarPosition}
      platformKind={platformKind}
      showDoneButton={showDoneButton}
      showSidepanelButton={showSidepanelButton}
      showHoveringTooltip={showHoveringTooltip}
      hostReadSpeed={hostReadSpeed}
      writeLock={writeLock}
      containerRef={containerRef}
    />
  </ToolbarWrapper>
);

export function getPlainText(elements: readonly (CustomElement | CustomText)[]): string {
  return elements.map((el) => ('text' in el ? el.text : getPlainText(el.children))).join('\n');
}

export function getDefaultValue(variant: EditorVariant, isAllowed: boolean) {
  return initialValues(variant, isAllowed, false, false, '');
}

function EditorView<T extends BaseEditor = BaseEditor>({
  background,
  height = 670,
  placeholder = 'Type something here...',
  readOnly = true,
  renderToolbar = defaultToolbar,
  update = async () => {},
  users = [],
  value = initialValues(variants.GENERAL, undefined, undefined, false),
  variant = variants.GENERAL,
  shouldResetSelection = false,
  width = '100%',
  onCmsEditing = () => {},
  isPublished = false,
  toolbarPosition = 'top',
  onDone = () => {},
  showHoveringTooltip = true,
  padding = 8,
  onFocus = () => {},
  onBlur = () => {},
  showDoneButton = false,
  enableEmoji = false,
  keepFocus = false,
  enableEditorCommand = false,
  resourceDetails,
  showSidepanelButton = false,
  writeLock = false,
  readLock = false,
  suppressChangeEvent = false,
  fallbackText = 'Content can not be loaded',
  direction = 'auto',
  hostReadSpeed = 150,
  isAllowed = false,
  isCmsBlock = false,
  editorFontSize = 'default',
  setEditor,
  thumbnail,
  platformStructure,
  platformId,
  getPlaceholderConfigs,
  withSignedUrl,
  platformKind,
  autoFocus,
  doLock,
  onSave,
  editorCustomization,
}: Readonly<EditorProps<T>>) {
  const containerRef = useRef<ElementRef<'div'> | null>(null);
  const [blockForms] = useBlockForms();
  const formsForThisPlatform = platformId
    ? (blockForms?.[platformId] as PlatformSpecificForm)
    : undefined;
  const editor = useMemo(
    () => wrappedEditor(variant, editorCustomization?.customizeEditor ?? ((e) => e))(),
    [variant, editorCustomization],
  );
  const [checkUserRight] = useCheckUserRight();
  const canUseEditorCommand = enableEditorCommand && checkUserRight('feature', 'mdfBlocks');
  const canSeeNewCmsWorkflow = isCmsBlock && checkUserRight('feature', 'cms-blocks');

  const initialValue = useMemo(() => {
    return initialValues(variant, isAllowed, isCmsBlock, canSeeNewCmsWorkflow, '');
  }, [variant, isAllowed, isCmsBlock, canSeeNewCmsWorkflow]);

  const { document, ...rest } = value ?? initialValue;
  const initialDocument = isValidDocument(document) ? document : initialValue.document;

  const disableGeneralToolbar = useMemo(() => {
    return isVoidSelected(editor) && ((isCmsBlock && variant === CMS) || variant === GENERAL);
  }, [editor]);

  const resetSelection = (editorToReset: CustomEditor | null) => {
    if (!editorToReset) return;

    const maxRetries = 3;
    for (let i = 0; i < maxRetries; i++) {
      try {
        const { deselect, blur } = ReactEditor;
        editorToReset.history = {
          redos: [],
          undos: [],
        };

        if (editorToReset.selection) {
          deselect(editorToReset);
          blur(editorToReset);
        }
      } catch (error) {
        if (i === maxRetries - 1) throw error;
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const shouldClearEditorContent = () => variant === variants.MESSAGE;

  const clearEditorContent = () => {
    const { document: emptyDocument } = initialValues(
      variant,
      isAllowed,
      isCmsBlock,
      canSeeNewCmsWorkflow,
      '',
    );
    editor.children = emptyDocument;
  };

  useMosItemReplaceHandler(editor, update, readOnly, variant);

  useLayoutEffect(() => {
    if (setEditor && editor) {
      setEditor(editor);
    }
  }, [setEditor, editor]);

  useEffect(() => {
    if (shouldResetSelection || !readOnly) {
      resetSelection(editor);
    }
    if (!suppressChangeEvent && (initialDocument || isNull(initialDocument))) {
      editor.children = initialDocument;
      editor.onChange();
    }
  }, [editor, initialDocument, readOnly, shouldResetSelection, suppressChangeEvent]);

  useLayoutEffect(() => {
    if (shouldResetSelection || !readOnly) {
      resetSelection(editor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly, shouldResetSelection]);

  const handleDone = useCallback(() => {
    onDone({ document: editor.children as CustomElement[], ...rest });
    resetSelection(editor);
    if (shouldClearEditorContent()) clearEditorContent();
    if (keepFocus) {
      ReactEditor.focus(editor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.children, onDone, keepFocus, rest]);

  const handleUpdate = useCallback(
    (input: UpdateInput) => {
      const { payload } = input;

      return update({
        ...input,
        payload: { ...payload, ...rest },
      } as UpdateInput);
    },
    [update, rest],
  );

  const onHotKeys = useCallback(
    async (event: React.KeyboardEvent, callbackFunction?: () => void | Promise<void>) => {
      if (isSaveKeyEvent(event)) {
        if (callbackFunction) await callbackFunction();
        event.preventDefault();
        event.stopPropagation();
        if (event.altKey && onSave) void onSave();
        else notifyChange(editor, update, 'userInitiated');
      }
    },
    [editor, onSave, update],
  );

  const handleChange = useCallback(
    async (newValue: CustomElement[]) => {
      const isAstChange = editor.operations.some((op) => op.type !== 'set_selection');
      if (isAstChange)
        await handleUpdate({
          type: ActionTypesEnum.CHANGE,
          payload: { document: newValue },
        });
    },
    [editor.operations, handleUpdate],
  );

  const toolbar = useMemo(
    () =>
      renderToolbar({
        variant,
        readOnly,
        isAllowed,
        platformStructure,
        disableGeneralToolbar,
        isCmsBlock,
        toolbarPosition,
        platformKind,
        showDoneButton,
        showSidepanelButton,
        showHoveringTooltip,
        hostReadSpeed,
        writeLock,
        containerRef,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      readOnly,
      renderToolbar,
      variant,
      disableGeneralToolbar,
      platformKind,
      platformStructure,
      showDoneButton,
      showHoveringTooltip,
      hostReadSpeed,
      writeLock,
      containerRef,
    ],
  );

  const contextProps: EditorContextProps = useMemo(
    () => ({
      doLock,
      isLockedByAnotherUser: readLock,
      update: handleUpdate,
      getPlaceholderConfig: getPlaceholderConfigs ?? getDefaultPlaceholderConfigs,
      containerRef,
      users,
      variant,
      thumbnail,
      isAllowed,
      onCmsEditing,
      isPublished,
      isCmsBlock,
      onDone: handleDone,
      editorFontSize,
      withSignedUrl,
      platformId,
      resourceDetails,
      allowVideoInPhotogallery: platformStructure?.allowVideoInPhotogallery,
      config: platformStructure?.config,
      formsForThisPlatform,
      onSave,
      onHotKeys,
    }),
    [
      doLock,
      handleUpdate,
      getPlaceholderConfigs,
      containerRef,
      users,
      variant,
      thumbnail,
      isAllowed,
      onCmsEditing,
      isPublished,
      readLock,
      isCmsBlock,
      handleDone,
      editorFontSize,
      withSignedUrl,
      platformId,
      resourceDetails,
      platformStructure,
      formsForThisPlatform,
      onSave,
      onHotKeys,
    ],
  );

  useEffect(() => {
    if (autoFocus) Transforms.select(editor, Editor.end(editor, []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  useEffect(() => {
    return editorCustomization?.onMounted(editor as T);
  }, [editorCustomization?.onMounted, editor]);

  const isReadOnly = readLock || (readOnly ? !canSeeNewCmsWorkflow : readOnly);

  const InjectedWrapper = editorCustomization?.editableWrapper;
  const editableProps: Parameters<typeof Editable>[0] = {
    editor,
    variant,
    height,
    padding,
    editorFontSize,
    onFocus,
    onBlur,
    placeholder: variant !== variants.NOTES ? placeholder : undefined,
    readOnly: isReadOnly,
    direction,
    isAllowed,
    isCmsBlock,
    platformStructure,
    InjectedWrapper,
  };

  return (
    <ScopeProvider scope={EditorScope} uniqueValue={true}>
      <EditorWrapper $background={background} $width={width} $height={height} ref={containerRef}>
        <ErrorBoundary
          fallback={<FallbackComponent fallbackText={fallbackText} />}
          resetKeys={[value]}
        >
          <Slate
            onChange={handleChange as (value: Descendant[]) => void}
            initialValue={initialDocument}
            editor={editor}
          >
            <EditorContext.Provider value={contextProps}>
              {toolbarPosition === toolbarPositions.TOP && toolbar}
              <HoveringToolbar />
              <Editable {...editableProps} />
              {toolbarPosition === toolbarPositions.BOTTOM && toolbar}
              <Suggestions />
              {enableEmoji && <EmojiCombobox />}
              {canUseEditorCommand && <UserCommands readOnly={isReadOnly} />}
              <Dialogs />
            </EditorContext.Provider>
          </Slate>
        </ErrorBoundary>
      </EditorWrapper>
    </ScopeProvider>
  );
}

export default memo(EditorView) as typeof EditorView;
