import React, { useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { useRemoteCursorOverlayPositions } from '@slate-yjs/react';
import { CaretPosition, SelectionRect } from '@slate-yjs/react/dist/utils/getOverlayPosition';

import { useAllMembersKeyed } from 'store';
import { getUserColor } from 'utils/userColor';

const CursorsComponent = styled('div')`
  position: relative;
`;

const CaretMarker = styled('div')`
  position: absolute;
  width: 2px;
`;

const CaretDiv = styled('div')`
  position: absolute;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  top: 0;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  padding: 2px 6px;
  pointer-events: none;
`;

const SelectionDiv = styled('div')`
  position: absolute;
  pointer-events: none;
  opacity: 0.2;
`;

interface Props {
  children: React.ReactElement;
}

const DUMMY_USER_ID = 'dummy-user-id';

/** Information about the owner of the cursor */
export type CursorData = {
  /** The ID of the user with the cursor */
  readonly userId: string;
};

function Caret({
  caretPosition,
  data,
}: Readonly<{ caretPosition: React.CSSProperties; data: CursorData | undefined }>) {
  const [allMembers] = useAllMembersKeyed();
  const name = (data?.userId && allMembers?.[data.userId]?.mTitle) ?? 'somebody';
  const background = getUserColor({ mId: data?.userId ?? DUMMY_USER_ID });
  const caretStyle = {
    ...caretPosition,
    background,
  };

  const labelStyle = {
    transform: 'translateY(-100%)',
    background,
  };

  return (
    <CaretMarker style={caretStyle}>
      <CaretDiv style={labelStyle}>{name}</CaretDiv>
    </CaretMarker>
  );
}

function Selection({
  data,
  selectionRects,
  caretPosition,
}: Readonly<{
  selectionRects: readonly SelectionRect[];
  data: CursorData | undefined;
  caretPosition: CaretPosition | null;
}>) {
  const backgroundColor = useMemo(
    () => getUserColor({ mId: data?.userId ?? DUMMY_USER_ID }),
    [data?.userId],
  );
  if (!data) {
    return null;
  }

  return (
    <>
      {selectionRects.map((position, i) => (
        <SelectionDiv style={{ backgroundColor, ...position }} key={i} />
      ))}
      {caretPosition && <Caret caretPosition={caretPosition} data={data} />}
    </>
  );
}

/**
 * Use this component to wrap the `Editable` component inside our (Slate-based) `Editor`.
 * The component only takes a children property (which should be the mentioned `Editable`)
 */
export function Cursors({ children }: Readonly<Props>) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [cursors] = useRemoteCursorOverlayPositions<CursorData>({ containerRef });

  return (
    <CursorsComponent ref={containerRef}>
      {children}
      {cursors.map((cursor) => (
        <Selection
          key={cursor.clientId}
          data={cursor.data}
          caretPosition={cursor.caretPosition}
          selectionRects={cursor.selectionRects}
        />
      ))}
    </CursorsComponent>
  );
}
