import { useCallback } from 'react';
import { ApolloCache, ApolloClient, gql, useMutation } from '@apollo/client';
import { capitalize } from 'lodash';

import useToast, { ShowToast } from 'components/toast/useToast';
import GET_INSTANCE from 'operations/queries/getInstance';
import { getStoryInstancesQuery } from 'operations/queryVariables';
import { Instance } from 'types';
import { MemberType, MoveItemsToStoryInput } from 'types/graphqlTypes';

const MOVE_ITEMS_TO_STORY = gql`
  mutation MoveItemsToStory($input: MoveItemsToStoryInput) {
    moveItemsToStory(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mStoryId
      mSecId
      mTemplateId
      isTemplateInstance
    }
  }
`;

const GET_MEMBER = gql`
  query GetMember($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      locked
      mType
      mProperties {
        platform
        platformKind
      }
    }
  }
`;

const GET_NOTES = gql`
  query GetNotes($input: GetNoteInput) {
    getNotes(input: $input) {
      mId
      mRefId
      mTitle
      locked
      mType
    }
  }
`;

type GetMember = {
  getMember: MemberType;
};

type GetNotes = {
  getNotes: MemberType[];
};

type GetInstances = {
  getMembersOfTypeBySecId: Instance[];
};

interface MoveItemsResult {
  moveItemsToStory: MemberType[];
}

interface MoveItemsInput {
  input: MoveItemsToStoryInput;
}

export const getNoteKeys = (selectedIds: string[], parentStoryId: string) =>
  selectedIds.map((mRefId) => ({ mId: parentStoryId, mRefId }));

export const getInstanceKeys = (selectedIds: string[]) =>
  selectedIds.map((mId) => ({ mId, mRefId: mId }));

export const getItemsFromCache = (
  client: ApolloClient<unknown>,
  keys: { mId: string; mRefId: string }[],
) =>
  keys
    .map(
      (key) =>
        client.readQuery<GetMember>({
          query: GET_MEMBER,
          variables: {
            input: key,
          },
        })?.getMember,
    )
    .filter((item) => !!item) as MemberType[];

export const getNotesFromCache = (
  client: ApolloClient<unknown>,
  storyId: string,
  keys: { mId: string; mRefId: string }[],
) => {
  const cachedNotes = client.readQuery<GetNotes>({
    query: GET_NOTES,
    variables: {
      input: {
        mId: storyId,
      },
    },
  });
  if (!cachedNotes) return [];
  return cachedNotes.getNotes.filter((note) => keys.find((key) => key.mRefId === note.mRefId));
};

export const appendWithSuffix = (count: number) => (count > 1 ? 's' : '');

const getErrorText = (count: number, operation: 'move' | 'copy' | 'add'): ShowToast => ({
  title: `${capitalize(operation)} failed`,
  description: `Failed to ${operation} ${count} item${appendWithSuffix(count)}`,
  type: 'error',
});

const updateStoryInstancesCache = (
  proxy: ApolloCache<unknown>,
  results: MemberType[],
  parentStoryId: string,
) => {
  const cachedQueryItem = proxy.readQuery<GetInstances>({
    query: GET_INSTANCE,
    variables: getStoryInstancesQuery(parentStoryId),
  });

  const cachedInstances = cachedQueryItem?.getMembersOfTypeBySecId ?? [];

  const updatedInstances = cachedInstances.filter(
    (cInstance) => !results.find((result) => cInstance.mId === result.mId),
  );

  proxy.writeQuery({
    query: GET_INSTANCE,
    variables: getStoryInstancesQuery(parentStoryId),
    data: {
      getMembersOfTypeBySecId: updatedInstances,
    },
  });
};

const useMoveItemsToStory = () => {
  const { errorToast, toast } = useToast();
  const [moveItemsToStory] = useMutation<MoveItemsResult, MoveItemsInput>(MOVE_ITEMS_TO_STORY);

  const moveItems = useCallback(async (input: MoveItemsToStoryInput, parentStoryId?: string) => {
    try {
      const result = await moveItemsToStory({
        variables: {
          input,
        },
        update: (proxy: ApolloCache<unknown>, mutationResult) => {
          const results = mutationResult.data?.moveItemsToStory;
          if (!results) return;

          const itemCount = results.length;
          const failedCount = input.keys.length - itemCount;

          if (failedCount) toast(getErrorText(failedCount, input.copy ? 'copy' : 'move'));

          if (itemCount)
            toast({
              title: input.copy ? 'Copied' : 'Moved',
              description: `${itemCount} item${appendWithSuffix(itemCount)} ${
                input.copy ? 'copied' : 'moved'
              } successfully`,
              type: 'success',
            });

          /* Don't update cache for copy operation */
          if (input.copy) return;
          /* Update cache for move operation */
          if (parentStoryId) updateStoryInstancesCache(proxy, results, parentStoryId);
        },
      });
      return result.data?.moveItemsToStory;
    } catch (err) {
      if (err instanceof Error) errorToast(err, err.message);
      return;
    }
  }, []);

  return { moveItemsToStory: moveItems };
};

export const useAttachInstanceToStory = () => {
  const { errorToast, toast } = useToast();
  const [moveItemsToStory] = useMutation<MoveItemsResult, MoveItemsInput>(MOVE_ITEMS_TO_STORY);

  const attachInstances = useCallback(async (input: MoveItemsToStoryInput) => {
    try {
      const result = await moveItemsToStory({
        variables: {
          input,
        },
        update: (_proxy: ApolloCache<unknown>, mutationResult) => {
          const results = mutationResult.data?.moveItemsToStory;
          if (!results) return;

          const itemCount = results.length;
          const failedCount = input.keys.length - itemCount;

          if (failedCount) toast(getErrorText(failedCount, 'add'));
        },
      });
      return result.data?.moveItemsToStory;
    } catch (err) {
      if (err instanceof Error) errorToast(err, err.message);
      return;
    }
  }, []);

  return { attachInstancesToStory: attachInstances };
};

export default useMoveItemsToStory;
