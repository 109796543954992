import { useCallback, useContext } from 'react';
import { ApolloCache, FetchResult, useMutation } from '@apollo/client';

import UserContext from 'contexts/UserContext';
import MODIFY_USER_IN_ORGANIZATION from 'operations/mutations/modifyUserInOrganization';
import GET_ORGANIZATIONS from 'operations/queries/getOrganizations';
import GET_ORGANIZATION_USERS from 'operations/queries/getOrganizationUsers';
import { useSwitchableOrganizations } from 'store/organization';
import type { ModifyUserInOrgInput, OrganizationUser } from 'types/graphqlTypes';
import { OrgAccessTypeEnum } from 'types/graphqlTypes';

import { FetchOrganizations } from './useGetOrganizations';
import { FetchOrganizationsUsers } from './useGetOrganizationUsers';

interface CreateOrganization {
  modifyUserInOrg: OrganizationUser;
}

function useModifyUserInOrganization() {
  const [addUser, { loading, error }] = useMutation<
    CreateOrganization,
    { input: ModifyUserInOrgInput }
  >(MODIFY_USER_IN_ORGANIZATION);
  const { mId } = useContext(UserContext);
  const [, setSwitchableOrganizations] = useSwitchableOrganizations();

  const updateCache = useCallback(
    (
      cache: ApolloCache<unknown>,
      mutationResult: Omit<FetchResult<CreateOrganization>, 'context'>,
      userId: string,
      orgId: string,
      isArchived?: boolean,
      isDisabled?: boolean,
    ) => {
      if (!mutationResult.data?.modifyUserInOrg) return;

      const orgCacheResult = cache.readQuery<FetchOrganizationsUsers>({
        query: GET_ORGANIZATION_USERS,
        variables: { input: { orgId } },
      });
      if (orgCacheResult?.getOrganizationUsers) {
        cache.writeQuery<FetchOrganizationsUsers>({
          query: GET_ORGANIZATION_USERS,
          variables: { input: { orgId } },
          data: {
            getOrganizationUsers: [
              mutationResult.data?.modifyUserInOrg,
              ...orgCacheResult.getOrganizationUsers.filter((u) => u.id !== userId),
            ],
          },
        });
      }

      if (userId !== mId) return;
      const cachedResult = cache.readQuery<FetchOrganizations>({
        query: GET_ORGANIZATIONS,
        variables: { input: { accessType: OrgAccessTypeEnum.Readable } },
      });
      if (!cachedResult) return;
      const org = cachedResult.getOrganizations.find((o) => o.id === orgId);
      if (!org) return;
      setSwitchableOrganizations((prev) => {
        const filtered = prev.filter((o) => o.id !== orgId);
        if (isArchived || isDisabled) return filtered;
        return [org, ...filtered];
      });
    },
    [mId, setSwitchableOrganizations],
  );

  const modifyUserInOrganization = useCallback(
    async (
      userId: string,
      orgId: string,
      groups?: string[],
      isArchived?: boolean,
      isDisabled?: boolean,
    ) => {
      const result = await addUser({
        variables: { input: { userId, orgId, groups, isArchived, isDisabled } },
        update: (cache, mutationResult) =>
          updateCache(cache, mutationResult, userId, orgId, isArchived, isDisabled),
      });
      return result.data?.modifyUserInOrg;
    },
    [addUser, updateCache],
  );

  return { modifyUserInOrganization, loading, error };
}

export default useModifyUserInOrganization;
