import { memo } from 'react';

import Avatar from 'components/avatar/Avatar';
import Popover from 'components/dialogs/PopoverBuilder';
import Text from 'components/text/Text';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { HStack, VStack } from 'layouts/box/Box';
import { useSelectedOrgId, useSwitchableOrganizations } from 'store/organization';

import { BackIcon, StyledClose } from './styled';

interface HeaderProps {
  avatar: string;
  username: string;
  showQR: boolean;
  qrBackButtonPressed: () => void;
}

const QR_DIALOG_HEADER_TEXT = 'Saga Mobile login quick start';

const Organization = () => {
  const [currentOrgId] = useSelectedOrgId();
  const [orgs] = useSwitchableOrganizations();
  const currentOrg = orgs.find((org) => org.id === currentOrgId);
  return currentOrg ? (
    <Text>Active organization : {currentOrg?.title ?? currentOrg?.id}</Text>
  ) : null;
};

function Header({ avatar, username, showQR, qrBackButtonPressed }: HeaderProps) {
  const [checkUserRight] = useCheckUserRight();
  const showOrganization = checkUserRight('feature', 'organization');
  return (
    <VStack>
      <Popover.Close asChild>
        <StyledClose />
      </Popover.Close>
      {showQR ? (
        <HStack width="100%">
          <BackIcon onClick={qrBackButtonPressed} role="presentation" />
          <Text variant="h7">{QR_DIALOG_HEADER_TEXT}</Text>
        </HStack>
      ) : (
        <HStack height="56px" padding="0 8px" gap="12px" margin="8px">
          <Avatar size={48} imageKey={avatar} variant="user" />
          <VStack>
            <Text variant="h5">{username}</Text>
            {showOrganization && <Organization />}
          </VStack>
        </HStack>
      )}
    </VStack>
  );
}

export default memo(Header);
