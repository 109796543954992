import styled from '@emotion/styled';

export const MasterHeader = styled('div')`
  height: 80px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialog};
`;

export const MasterFirstRow = styled('span')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 4px 8px 4px 8px;
`;

export const MasterSecondRow = styled('span')`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin: 0 8px 4px 8px;
  gap: 4px;
`;
