import React, { useEffect } from 'react';
import Scrollbar from 'components/scrollbar';
import RadioButton from 'components/buttons/radioButton';
import DarkSrc from 'assets/images/theme/UserInterface-Dark.webp';
import LightSrc from 'assets/images/theme/UserInterface-Light.webp';
import SystemSrc from 'assets/images/theme/UserInterface-System.webp';
import { useDinaTheme, useChangedTheme } from 'store';

import { useChangedUserSettings } from '../../../../../atomsTs';

import {
  ContentWrapper,
  TitleWrapper,
  Title,
  OptionsWrapper,
  Subtitle,
  Options,
  Option,
  RadioButtonWrapper,
  RadioTitle,
  OptionImage,
} from './styled';

function UserTheme() {
  const [theme] = useDinaTheme();
  const [changedTheme, setChangedTheme] = useChangedTheme();
  const [changedUserSettings, setChangedUserSettings] = useChangedUserSettings();

  useEffect(() => {
    const changedValue = changedUserSettings?.find(
      (changedSetting) => changedSetting.key === 'theme',
    );
    if (changedValue) {
      setChangedTheme(changedValue.value);
    } else {
      setChangedTheme(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedUserSettings, changedTheme]);

  const onChangeThemeSettings = (val, contentKey, originalValue) => {
    const changedSetting = changedUserSettings.find((x) => x.key === contentKey);
    if (changedSetting) {
      if (changedSetting?.originalValue === val) {
        setChangedUserSettings(changedUserSettings.filter((x) => x.key !== changedSetting.key));
      } else {
        changedSetting.value = val;
      }
    } else {
      setChangedUserSettings([
        ...changedUserSettings,
        {
          key: contentKey,
          value: val,
          originalValue,
        },
      ]);
    }
  };

  const currentTheme = changedTheme || theme;

  const setTempTheme = (newTheme) => {
    if (newTheme !== theme) setChangedTheme(newTheme);
    if (newTheme === theme && changedTheme) setChangedTheme(undefined);
    onChangeThemeSettings(newTheme, 'theme', theme);
  };

  return (
    <ContentWrapper>
      <TitleWrapper>
        <Title>User Interface Settings</Title>
      </TitleWrapper>
      <Scrollbar>
        <OptionsWrapper>
          <Subtitle>Choose the appearance of Saga</Subtitle>
          <Options>
            <Option onClick={() => setTempTheme('dark')}>
              <RadioButtonWrapper>
                <RadioButton size={24} selected={currentTheme === 'dark'} />
                <RadioTitle>Dark theme (default)</RadioTitle>
              </RadioButtonWrapper>
              <OptionImage src={DarkSrc} />
            </Option>
            <Option onClick={() => setTempTheme('light')}>
              <RadioButtonWrapper>
                <RadioButton size={24} selected={currentTheme === 'light'} />
                <RadioTitle>Light theme</RadioTitle>
              </RadioButtonWrapper>
              <OptionImage src={LightSrc} />
            </Option>
            <Option onClick={() => setTempTheme('system')}>
              <RadioButtonWrapper>
                <RadioButton size={24} selected={currentTheme === 'system'} />
                <RadioTitle>System settings</RadioTitle>
              </RadioButtonWrapper>
              <OptionImage src={SystemSrc} />
            </Option>
          </Options>
        </OptionsWrapper>
      </Scrollbar>
    </ContentWrapper>
  );
}
export default UserTheme;
