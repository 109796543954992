const UserSettingsView = {
  id: 'user-settings',
  name: 'Settings',
  categories: [
    {
      title: 'notification',
      description: 'Notification settings',
      visible: true,
      properties: {
        'user.breakingFeedNotifications': {
          label: 'Saga Notifications',
          type: 'boolean',
          description: 'Breaking feed notifications',
          visible: true,
          defaultValue: false,
        },
        'user.desktopNotifications': {
          label: 'Desktop notifications',
          type: 'boolean',
          default: false,
          description: 'Desktop notifications',
          visible: true,
          defaultValue: false,
          fallbackProperty: 'app.desktopNotifications',
          childKey: 'user.notificationSound',
        },
        'user.notificationSound': {
          label: 'Play sound on notifications',
          type: 'boolean',
          default: true,
          description: 'Play sound on notifications',
          defaultValue: true,
          visible: false,
          parentKey: 'user.desktopNotifications',
        },
        'user.displayLanguage': {
          label: 'Display Language for Saga',
          type: 'select',
          default: 'en',
          options: [
            {
              id: 'en',
              title: 'English',
              value: 'en',
            },
            {
              id: 'bn',
              title: 'বাংলা',
              value: 'bn',
            },
            {
              id: 'no',
              title: 'Norsk',
              value: 'no',
            },
          ],
          visible: false,
        },
      },
    },
    {
      title: 'theme',
      defaultValue: 'dark',
    },
  ],
};

export default UserSettingsView;
