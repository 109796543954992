/**
 *  Hook for creating a new master rundown.
 */

import { useMutation } from '@apollo/client';
import GET_RUNDOWNS from 'operations/queries/getRundowns';
import GET_ALL_RUNDOWN_TEMPLATES from 'operations/queries/getAllRundownTemplates';
import CREATE_MASTER_RUNDOWN from 'operations/mutations/createMasterRundown';
import useLogger from 'utils/useLogger';
import { getLocalTimezone } from './useTimezoneSettings';

const useCreateMasterRundown = () => {
  const logger = useLogger('use create master rundown');
  const [createRundown] = useMutation(CREATE_MASTER_RUNDOWN);
  let rundown;

  const create = async (rundownTitle, platformKind, userId, globalsOnly, orgId) => {
    await createRundown({
      variables: {
        input: {
          mUpdatedById: userId,
          mTitle: rundownTitle,
          platformKind,
          recurrence: {
            startTime: '19:00:00', // 7PM local time
            timeZone: getLocalTimezone(),
            dailyExclusive: true,
          },
        },
      },
      update: (proxy, mutationResult) => {
        const { createMasterRundown } = mutationResult.data;
        try {
          const variables = { mId: '', globalsOnly };
          if (orgId) variables.orgId = orgId;

          const rundownList = proxy.readQuery({
            query: GET_RUNDOWNS,
            variables,
          });
          const templateRundownList = proxy.readQuery({
            query: GET_ALL_RUNDOWN_TEMPLATES,
            variables,
          });

          const updatedItems = [createMasterRundown, ...(rundownList.getRundowns ?? [])];
          const updatedTemplateItems = [
            createMasterRundown,
            ...(templateRundownList?.getAllRundownTemplates ?? []),
          ];

          proxy.writeQuery({
            query: GET_RUNDOWNS,
            variables,
            data: {
              getRundowns: updatedItems,
            },
          });
          proxy.writeQuery({
            query: GET_ALL_RUNDOWN_TEMPLATES,
            variables,
            data: {
              getAllRundownTemplates: updatedTemplateItems,
            },
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          logger.error(err);
        }
        rundown = createMasterRundown;
      },
    });

    return rundown;
  };

  return [create];
};

export default useCreateMasterRundown;
