import { useContext } from 'react';
import xorBy from 'lodash/xorBy';

import UserContext from 'contexts/UserContext';
import useGetUser from 'hooks/useGetUser';
import { useMembers } from 'store/members';
import { User } from 'types';
import { ConversationTypeEnum } from 'types/graphqlTypes';
import { Conversation } from 'types/messageHub';

const DELETED_USER = 'Ghost User';

const getFilteredMembers = (members: User[], userId: string) => {
  if (!Array.isArray(members)) return [];
  const filteredMembers = members.filter(({ mId }) => mId !== userId);
  return filteredMembers;
};

const getAvatarKey = (item: Conversation, members: User[]) => {
  const member = members.find((t) => t.mId === item.mId);
  const { mAvatarKey } = member ?? {};
  return mAvatarKey;
};

const useConversationInfo = () => {
  const user = useContext(UserContext);
  const { getUser, getUsers } = useGetUser();
  const [members] = useMembers();

  const getConversationInfo = (member: Conversation) => {
    const conversation = { ...member };
    switch (member.convoType) {
      case ConversationTypeEnum.Group: {
        const filteredMembers = getFilteredMembers(member.mAssignedMembers ?? [], user.mId);
        const convoUsers = getUsers(filteredMembers?.map((m) => m.mId));

        if (filteredMembers.length !== convoUsers.length) {
          const deletedUsers = xorBy(filteredMembers, convoUsers, 'mId');
          deletedUsers.forEach((deletedUser) => {
            convoUsers.push({ ...deletedUser, mTitle: DELETED_USER });
          });
        }

        conversation.name = convoUsers
          .map((convoUser) => convoUser.mTitle ?? DELETED_USER)
          .join(', ');
        conversation.mAvatarKeys = convoUsers.map((convoUser) => convoUser.mAvatarKey);
        break;
      }
      case ConversationTypeEnum.Direct: {
        const filteredMembers = getFilteredMembers(member.mAssignedMembers ?? [], user.mId);
        const convoUser = getUser(filteredMembers[0]?.mId);
        conversation.name = convoUser?.mTitle ?? 'Ghost User';
        conversation.mAvatarKey = convoUser?.mAvatarKey;
        break;
      }
      case ConversationTypeEnum.All: {
        conversation.name = 'Everyone';
        break;
      }
      case ConversationTypeEnum.Notification: {
        conversation.name = 'Saga Notifications';
        break;
      }
      case ConversationTypeEnum.Team: {
        const mAvatarKey = getAvatarKey(member, members.team);
        conversation.mAvatarKey = mAvatarKey;
        break;
      }
      case ConversationTypeEnum.Department: {
        const mAvatarKey = getAvatarKey(member, members.department);
        conversation.mAvatarKey = mAvatarKey;
        break;
      }
      default:
        break;
    }
    return conversation;
  };

  const getSortedConversations = (conversations: Conversation[]) =>
    conversations
      .map((member) => getConversationInfo(member))
      .sort((a, b) => (a.mUpdatedAt && b.mUpdatedAt && a?.mUpdatedAt < b?.mUpdatedAt ? 1 : -1));

  return { getConversationInfo, getSortedConversations };
};

export default useConversationInfo;
