import { getDinaSessionId } from 'utils/sessionId';

const tokenName = 'lockToken';

const getUserLockToken = (userId: string, timeStamp?: string) => {
  const lockToken = window.sessionStorage.getItem(tokenName);
  if (lockToken && !timeStamp) return lockToken;
  const exclusivity = timeStamp === '' ? 'exclusive' : 'collaborative';

  if (!timeStamp) timeStamp = new Date().toISOString();
  const token = `${userId}||${timeStamp}||unscoped||${getDinaSessionId(userId)}||${exclusivity}`;
  window.sessionStorage.setItem(tokenName, token);

  return token;
};

const resetLockToken = () => {
  window.sessionStorage.setItem(tokenName, '');
};

const getUserIdFromLockedId = (lockedId: string) => {
  if (!lockedId) return null;
  return lockedId.split('||')[0];
};

export { getUserLockToken, getUserIdFromLockedId, resetLockToken };
