/* eslint-disable no-console */
/**
 * This hook uses appsync events to keep track of users who are looking at a shared resource.
 * It starts by connecting to a channel for the resource and for to a channel for unicast events
 * to myself and to send a `connect` event to the resource's channel
 * The corresponding hooks in other sessions will respond by repeatedly uni-casting a
 * `connectionConfirmed` event back to this hook and will do so until they have received a
 * uni-casted `connected` event.
 * In addition this hook will send some initial `heartBeat` events to ensure that hooks that
 * connected to the resource's channel simultaneously are aware of me.
 * If a hook receives a heartbeat without first receiving a connect event will send a uni-casted
 * `requestConnection` event back and wait for a `acceptConnection` event.
 * When the hook is unmounted (or window is hidden), it will send a `disconnect` event to the
 * resource's channels and will stop listening to events from the resource's channels.
 * @module
 */

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { v4 } from 'uuid';

import { useAuthContext } from 'contexts/AuthContext';
import { CustomChannel, CustomEventData } from 'types/customChannel';
import { getDinaSessionId } from 'utils/sessionId';
import VisibilityProvider, { ManualVisibilityProvider } from 'utils/visibilityProvider';

import useStabilizedArray from '../useStabilizedArray';

import { createAppsyncEventsCommunicationChannel } from './appsyncEventsChannel';
import { createReceiptAwareChannel } from './receiptAwareChannel';
import type {
  CommunicationChannelFactory,
  CustomEventHandler,
  CustomEventHandlerMap,
  IsReadyHandlers,
  Participant,
} from './types';
import useParticipants from './useParticipants';
import {
  addCustomEventHandler,
  createCustomEvent,
  getNextSessionIndex,
  getSessionIdFromParticipant,
  handleCustomEvent,
} from './utils';

const NO_OP = () => undefined;
const NO_OP_RESULT = () => NO_OP;

export type { Participant } from './types';
export { getSessionUser, getUserIdFromParticipant } from './utils';

/**
 * The result object returned from {@link useSharedResource}
 * @template T The type of the state of the shared resource
 */
export interface SharedResourceUsage<T> {
  /** The other users that looks at the same resource */
  others: readonly Participant<T>[];

  /**
   * Updates the state of the resource as seen by the caller
   * @param state        The new state of the resource
   * @param debounceTime The time in milliseconds to wait fore more updates before sending
   *                     the update event.
   *                     If not set, the event will be sent immediately.
   */
  updateState: (state: T, debounceTime?: number) => void;

  /** `true` if the event API for the communication has configured, otherwise `false` */
  configured: boolean;

  /** A {@link CustomChannel} that can be used to send/receive custom events */
  customChannel: CustomChannel;
}

/**
 * Properties for the {@link useSharedResource} hook
 * @template T The type of the state of the shared resource
 */
export interface SharedResourceProps<T> {
  /**
   * The initial state of the channel. Will only be used the first time the hook is used in a scope.
   * To update the state afterwards use the {@link SharedResourceUsage.updateState} method.
   */
  initialState: T;

  /**
   * If set to `true`, no subscription will be created and no messages sent
   */
  disabled?: boolean;

  /**
   * Optional locked state of the shared resource. Only one resource client should be allowed to be
   * locked (`undefined` if not locked)
   */
  myLock?: string | undefined;

  /**
   * Optional {@link CommunicationChannelFactory} function for creating the communication channel.
   * If not provided, an appsync events channel will be created.
   */
  channelFactory?: CommunicationChannelFactory<T>;

  /**
   * Optional visibility provider to be used. Defaults to browser's `document`.
   * This property should not changed during the lifetime of the hook.
   */
  visibilityProvider?: VisibilityProvider;
}

/**
 * Simple {@link SharedResourceProps} that can be used with {@link useSharedResource} if no state
 * is involved in the channel.
 */
export const STATELESS_PROPS: SharedResourceProps<undefined> = Object.freeze({
  initialState: undefined,
});

/**
 * Uses a shared resource identified by its channel
 * @param channelNamespace The namespace for the resource. Must not contain more than four parts
 *                         (the parts are separated by slashes) and each part must contain maximum
 *                         50 characters where each character is alphanumerical or dash (-).
 * @param props            {@link SharedResourceProps}`<T>` for the hook
 * @returns                The {@link SharedResourceUsage}
 * @template T             The type of the state of the shared resource
 */
export default function useSharedResource<T>(
  channelNamespace: string,
  props: Readonly<SharedResourceProps<T>>,
): SharedResourceUsage<T> {
  const enabled = !props.disabled;
  const myLock = props.myLock || undefined;
  const initialState = props.initialState;
  const context = useAuthContext();
  const userId = context.user?.dinaUserId ?? '';
  const customEventHandlers = useMemo<CustomEventHandlerMap>(() => ({}), []);
  const isReadyHandlers = useMemo<IsReadyHandlers>(() => [], []);
  const isReadyRef = useRef(false);
  const stateRef = useRef(initialState);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const configured = useMemo(
    () => (!!props.channelFactory || !!import.meta.env.REACT_APP_API_EVENTS_ENDPOINT) && enabled,
    [],
  );
  const connectable = configured && !!channelNamespace;
  const dinaSessionId = useMemo(() => getDinaSessionId(userId), [userId]);
  const myId = useMemo(() => `${dinaSessionId}X${getNextSessionIndex()}`, [dinaSessionId]);
  const myLockRef = useRef(myLock);
  const visibilityProviderRef = useRef<VisibilityProvider | undefined>(props.visibilityProvider);
  const outerVisibilityProvider: VisibilityProvider = visibilityProviderRef.current ?? document;

  /**
   * Gets the visibility to be used based on outer visibility and locked state
   * (will always be "visible" if locked).
   */
  const getVisibility = useCallback(
    () => (myLockRef.current ? 'visible' : outerVisibilityProvider.visibilityState),
    [outerVisibilityProvider],
  );

  const visibilityProvider = useMemo(() => new ManualVisibilityProvider(getVisibility()), []);

  const {
    clearParticipants,
    participants,
    removeParticipant,
    updateParticipant,
    updateParticipantLocking,
  } = useParticipants<T>();

  const rawCommunicationChannel = useMemo(
    () => (props.channelFactory ?? createAppsyncEventsCommunicationChannel)(channelNamespace, myId),
    [channelNamespace, myId, props.channelFactory],
  );
  const communicationChannel = useMemo(
    () => createReceiptAwareChannel(rawCommunicationChannel, myId),
    [rawCommunicationChannel, myId],
  );
  const communicationChannelRef = useRef(communicationChannel);
  if (communicationChannelRef.current !== communicationChannel) {
    communicationChannelRef.current = communicationChannel;
  }

  const rawOthers = useMemo(() => {
    // Expose max one participant per session and make it the locked one if existing
    // (and the first one if none are locked)
    const result: Participant<T>[] = [];
    const sessionIdPos = new Map<string, number>();
    participants.forEach((p) => {
      if (p.sessionId === dinaSessionId) return;
      const prevPos = sessionIdPos.get(p.sessionId);
      if (prevPos !== undefined && (result[prevPos].lock || !p.lock)) return;
      // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
      const { scopeId, ...item } = p;
      Object.freeze(item);
      if (prevPos === undefined) {
        sessionIdPos.set(p.sessionId, result.length);
        result.push(item);
      } else if (p.lock && !result[prevPos].lock) {
        result[prevPos] = item;
      }
    });
    return Object.freeze(result);
  }, [participants]);
  const others = useStabilizedArray(rawOthers, getSessionIdFromParticipant);

  if (myLockRef.current !== myLock) {
    myLockRef.current = myLock;
    visibilityProvider.visibilityState = getVisibility();
  }

  const updateState = useCallback((state: T, debounceTime?: number) => {
    stateRef.current = state;
    if (timeoutRef.current !== undefined) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
    function sendUpdateIfReady() {
      if (!isReadyRef.current) return;
      communicationChannelRef.current.send(null, { type: 'update', state, sourceId: myId });
    }
    if (debounceTime !== undefined) {
      timeoutRef.current = setTimeout(sendUpdateIfReady, debounceTime);
    } else {
      sendUpdateIfReady();
    }
  }, []);

  const broadcastCustomEvent = useCallback(
    (type: string, data?: CustomEventData) => {
      communicationChannel.send(null, createCustomEvent(myId, type, data));
    },
    [communicationChannel, myId],
  );

  const unicastCustomEvent = useCallback(
    (targetId: string, type: string, data?: CustomEventData) => {
      communicationChannel.send(targetId, createCustomEvent(myId, type, data, v4()));
    },
    [communicationChannel, myId],
  );

  const addEventListener = useCallback(
    (type: string, handler: CustomEventHandler) =>
      addCustomEventHandler(customEventHandlers, type, handler),
    [customEventHandlers],
  );

  const addIsReadyListener = useCallback(
    (handler: (isReady: boolean) => void) => {
      const handlers = isReadyHandlers;
      handlers.push(handler);
      return () => {
        const pos = handlers.indexOf(handler);
        if (pos >= 0) handlers.splice(pos, 1);
      };
    },
    [isReadyHandlers],
  );

  const customChannel: CustomChannel = useMemo(
    () =>
      connectable
        ? {
            myId,
            get isReady() {
              return isReadyRef.current;
            },
            addEventListener,
            addIsReadyListener,
            broadcastEvent: broadcastCustomEvent,
            unicastEvent: unicastCustomEvent,
            connectable: true,
          }
        : {
            myId,
            isReady: false,
            addEventListener: NO_OP_RESULT,
            addIsReadyListener: NO_OP_RESULT,
            broadcastEvent: NO_OP,
            unicastEvent: NO_OP,
            connectable: false,
          },
    [
      addEventListener,
      addIsReadyListener,
      removeEventListener,
      broadcastCustomEvent,
      connectable,
      myId,
    ],
  );

  // This effect notifies collaborating parties about me taking the lock and ensures that the
  // inverse event (lock released) is sent on the same channel when the lock released.
  useEffect(() => {
    if (!myLock) return;
    const channel = communicationChannelRef.current;
    if (isReadyRef.current) {
      channel.send(null, { type: 'locking', sourceId: myId, lock: myLock });
    }
    return () => channel.send(null, { type: 'locking', sourceId: myId, lock: undefined });
  }, [myLock, myId, isReadyRef, communicationChannelRef]);

  // Make sure we update visibility when outer visibility change
  useEffect(() => {
    const outerVisibilityListener = () => (visibilityProvider.visibilityState = getVisibility());
    outerVisibilityProvider.addEventListener('visibilitychange', outerVisibilityListener);
    return () =>
      outerVisibilityProvider.removeEventListener('visibilitychange', outerVisibilityListener);
  }, [getVisibility, outerVisibilityProvider]);

  useEffect(() => {
    if (connectable) {
      const pendingConnections: Record<string, ReturnType<typeof setInterval>> = {};
      const connections = new Set<string>();
      let initialHeartbeatInterval: ReturnType<typeof setInterval> | undefined = undefined;
      let initialHeartbeatCount = 0;

      const ensureConnectionConfirmed = (targetId: string) => {
        if (targetId in pendingConnections) return;
        let count = 0;
        pendingConnections[targetId] = setInterval(() => {
          // Don't try more than 10 times
          if (count++ > 10 && targetId in pendingConnections) {
            clearInterval(pendingConnections[targetId]);
            delete pendingConnections[targetId];
          }
          communicationChannel.send(targetId, {
            type: 'connectionConfirmed',
            sourceId: myId,
            userId,
            lock: myLockRef.current,
            state: stateRef.current,
          });
        }, 500);
      };

      const connectAndSubscribe = async () => {
        const connected = await communicationChannel.connectAsync((event) => {
          if (event.sourceId === myId) return; // Don't consider broadcasted messages from myself
          const [sessionId, scopeId] = event.sourceId.split('X');
          switch (event.type) {
            case 'disconnect':
              removeParticipant(sessionId, scopeId);
              if (pendingConnections[event.sourceId]) {
                // No need to confirm connection with source if disconnect sent from source
                clearInterval(pendingConnections[event.sourceId]);
                delete pendingConnections[event.sourceId];
              }
              break;
            case 'update':
              updateParticipant(sessionId, scopeId, event.state);
              break;
            case 'locking':
              updateParticipantLocking(sessionId, scopeId, event.lock);
              break;
            case 'connect':
              updateParticipant(sessionId, scopeId, event.state, {
                userId: event.userId,
                lock: event.lock,
              });
              connections.add(event.sourceId);
              ensureConnectionConfirmed(event.sourceId);
              break;
            case 'heartBeat':
              if (!(event.sourceId in pendingConnections) && !connections.has(event.sourceId)) {
                communicationChannel.send(event.sourceId, {
                  type: 'requestConnection',
                  sourceId: myId,
                  userId: userId,
                  lock: myLockRef.current,
                  state: stateRef.current,
                });
              }
              break;
            case 'requestConnection':
              updateParticipant(sessionId, scopeId, event.state, {
                userId: event.userId,
                lock: event.lock,
              });
              connections.add(event.sourceId);
              communicationChannel.send(event.sourceId, {
                type: 'acceptConnection',
                sourceId: myId,
                userId: userId,
                lock: myLockRef.current,
                state: stateRef.current,
              });
              break;
            case 'acceptConnection':
              updateParticipant(sessionId, scopeId, event.state, {
                userId: event.userId,
                lock: event.lock,
              });
              connections.add(event.sourceId);
              break;
            case 'connectionConfirmed':
              updateParticipant(sessionId, scopeId, event.state, {
                userId: event.userId,
                lock: event.lock,
              });
              connections.add(event.sourceId);
              communicationChannel.send(event.sourceId, {
                type: 'connected',
                sourceId: myId,
              });
              break;
            case 'connected':
              if (event.sourceId in pendingConnections) {
                clearInterval(pendingConnections[event.sourceId]);
                delete pendingConnections[event.sourceId];
              }
              break;
            case 'custom':
              handleCustomEvent(customEventHandlers, event);
              break;
          }
        });

        if (!connected) return;

        communicationChannel.send(null, {
          type: 'connect',
          sourceId: myId,
          userId,
          lock: myLockRef.current,
          state: stateRef.current,
        });

        isReadyRef.current = true;
        isReadyHandlers.forEach((handler) => handler(true));

        // When two connects simultaneously, they may miss connect messages from each other.
        // We therefore sends some heart beats so that we can do request/accept connection instead
        initialHeartbeatInterval = setInterval(() => {
          communicationChannel.send(null, { type: 'heartBeat', sourceId: myId });

          if (initialHeartbeatCount++ > 4) {
            clearInterval(initialHeartbeatInterval);
            initialHeartbeatInterval = undefined;
          }
        }, 300);
        initialHeartbeatCount = 0;
      };

      const disconnect = () => {
        if (isReadyRef.current) {
          isReadyRef.current = false;
          isReadyHandlers.forEach((handler) => handler(false));
          communicationChannel.send(null, { type: 'disconnect', sourceId: myId });
        }
        communicationChannel.disconnect();
        clearParticipants();
        const ids: string[] = [];
        Object.entries(pendingConnections).forEach(([id, interval]) => {
          clearInterval(interval);
          ids.push(id);
        });
        ids.forEach((id) => delete pendingConnections[id]);
        connections.clear();
        if (initialHeartbeatInterval !== undefined) {
          clearInterval(initialHeartbeatInterval);
          initialHeartbeatInterval = undefined;
        }
      };

      if (visibilityProvider.visibilityState === 'visible') {
        void connectAndSubscribe();
      }

      const onVisibilityChange = () => {
        if (visibilityProvider.visibilityState === 'hidden') {
          disconnect();
        } else {
          void connectAndSubscribe();
        }
      };
      visibilityProvider.addEventListener('visibilitychange', onVisibilityChange);

      return () => {
        disconnect();
        visibilityProvider.removeEventListener('visibilitychange', onVisibilityChange);
        Object.keys(customEventHandlers).forEach((type) => delete customEventHandlers[type]);
        isReadyHandlers.splice(0, isReadyHandlers.length);
      };
    } else {
      return NO_OP;
    }
  }, [
    channelNamespace,
    myId,
    clearParticipants,
    updateParticipant,
    removeParticipant,
    connectable,
    visibilityProvider,
    dinaSessionId,
  ]);

  if (!connectable) {
    return { others, updateState: NO_OP, configured, customChannel };
  }
  return { others, updateState, configured, customChannel };
}
