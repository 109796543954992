import { useContext, useMemo } from 'react';
import styled from '@emotion/styled';

import { CollaborationAwareEditor, CollaborationAwareEditorProps } from 'components/collaboration';
import useCollaboration from 'components/collaboration/useCollaboration';
import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import variants from 'components/editor/constants/types/editorVariants';
import { Update } from 'components/editor/types';
import { useBeforeUnloadDocument } from 'components/genericEditor/hooks/useBeforeUnloadDocument';
import usePreparedUnlock from 'components/genericEditor/hooks/usePreparedUnlock';
import { useTakeoverLockAsync } from 'components/genericEditor/hooks/useTakeoverLockAsync';
import LockedIndicator from 'components/lockedIndicator/LockedIndicator';
import StatusSelect from 'components/statusSelect/StatusSelect';
import UserContext from 'contexts/UserContext';
import InfoSummary from 'features/instance/components/infoSummary/InfoSummary';
import useGetUser from 'hooks/useGetUser';
import useTransformedCallback from 'hooks/usePipedCallback';
import useReadonlyRefOnGetter from 'hooks/useReadonlyRefOnGetter';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { Box } from 'layouts/box/Box';
import { useStoryToolbar } from 'screens/storyV2/store/toolbar';
import { useUsers } from 'store';
import { ColorVariants } from 'types';

import useSyncScript from './api/useSyncScript';
import useScriptEditor from './hooks/useScriptEditor';
import useScriptScope from './hooks/useScriptScope';
import useScriptStates from './hooks/useScriptStates';
import useWrapperMouseEvents from './hooks/useWrapperMouseEvents';
import { useScriptContent } from './store/content';
import { useScriptLock } from './store/lock';
import { SCRIPT_TRAITS, transformUnlockProps } from './utils/scriptTraits';
import { useScriptStore } from './store';

const EditorWrapper = styled('main')`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.dina.surfaceCardDark};
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Footer = styled('footer')<{
  $showLock: boolean;
  $backgroundColor: keyof ColorVariants;
  $opacity: number;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ $showLock }) => ($showLock ? '80px' : '40px')};

  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

interface ScriptProps {
  resourceDetails: ResourceDetails;
}

const Script = ({ resourceDetails }: ScriptProps) => {
  const { data, loading, error, refetch } = useSyncScript(resourceDetails.resource.mId);

  const [users] = useUsers();
  const { getUser } = useGetUser();
  const [{ editorFontSize }] = useStoryToolbar();
  const { mState, states, updateState } = useScriptStates();
  const { lockScript, unlockScript } = useScriptLock();
  const { getEditorValue, saveTimeoutRef } = useScriptContent();
  const {
    state: { lockedBy, script },
  } = useScriptStore();

  const scope = useScriptScope();
  const { mId: currentUserId } = useContext(UserContext);

  const {
    shouldResetSelection,
    readLock,
    writeLock,
    content,
    lockedUserId,
    isSaving,
    isLoading,
    contentLoading,
    scriptDuration,
    onFocusEditor,
    onCancel,
    onForceUnlock,
    onEditorUpdate,
    onSave,
  } = useScriptEditor();

  const lockedByUser = useMemo(
    () => (lockedUserId ? getUser(lockedUserId) : undefined),
    [getUser, lockedUserId],
  );

  const lockAsync = useTakeoverLockAsync(lockScript, currentUserId);
  const locking = writeLock && isLoading;
  const collabOptions = { writeLock, lockedBy, locking, lockAsync };
  const { editorInfo, lockBarInfo } = useCollaboration('script', script?.mId, collabOptions);

  // Ensure that edited document is saved and unlock if editor is closed
  const editorValueRef = useReadonlyRefOnGetter(getEditorValue);
  const unlock = useTransformedCallback(transformUnlockProps, unlockScript);
  const preparedUnlock = usePreparedUnlock(lockBarInfo.prepareUnlockOnSave, unlock);
  useBeforeUnloadDocument(
    {
      doc: script,
      scope,
      currentUserId,
      lockedBy,
      isLoading,
      editorValueRef,
      saveTimeoutRef,
      preparedUnlock,
    },
    SCRIPT_TRAITS,
  );

  const { onClick, onDoubleClick, creating, createError } = useWrapperMouseEvents({
    scope,
    resourceDetails,
    shouldCreateScript: loading ? false : !data?.mRefId,
    collaborationEnabled: editorInfo.collaborationEnabled,
    onFocusEditor,
    refetch,
  });

  const editorProps: CollaborationAwareEditorProps = {
    variant: variants.SCRIPT,
    value: content,
    update: onEditorUpdate as unknown as Update,
    onSave,
    users,
    isAllowed: true,
    placeholder: 'Type Something...',
    height: '100%',
    shouldResetSelection,
    editorFontSize,
    readLock,
    writeLock,
    collaborationInfo: editorInfo,
  };

  if (error || createError) {
    return <div>Error</div>;
  }

  return (
    <Box
      container
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <DebouncedLoadingIndicator isLoading={loading || creating || contentLoading} />
      <EditorWrapper onClick={onClick} onDoubleClick={onDoubleClick}>
        <CollaborationAwareEditor {...editorProps} />
      </EditorWrapper>
      <Footer
        $showLock={readLock || writeLock || !!lockBarInfo.others.length}
        $backgroundColor={mState.backgroundColor as keyof ColorVariants}
        $opacity={mState.opacity ?? 1}
      >
        <Box
          container
          padding="0.25rem"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <InfoSummary disabled duration={scriptDuration} title="Script" />
          <StatusSelect
            value={mState.id}
            onChange={(status: string) => updateState(status)}
            options={states}
          />
        </Box>
        <LockedIndicator
          readLock={readLock}
          writeLock={writeLock}
          onCancel={onCancel}
          lockedId={lockedUserId ?? ''}
          lockedBy={lockedByUser?.mTitle ?? 'Someone'}
          isSaving={isSaving}
          onDone={onSave}
          onForceUnlock={onForceUnlock}
          disableSave={!writeLock || contentLoading || isSaving || loading || isLoading}
          collaborationInfo={lockBarInfo}
        />
      </Footer>
    </Box>
  );
};

export default Script;
