import { useCallback, useRef } from 'react';
import { atom, useAtom } from 'jotai';
import { isEmpty, isEqual } from 'lodash';

import { isStartGroup } from '../utils/helper';
import { SlimRow } from '../utils/types';

export type Source = {
  type: 'ready' | 'preparing';
  rundownId: string;
};

export const selectedRowsAtom = atom<Record<string, boolean>>({});
export const useSelectedRowsAtom = () => useAtom(selectedRowsAtom);

export const useSelectedRundownRows = () => {
  const [, setSelected] = useSelectedRowsAtom();
  const currentType = useRef<Source | null>(null);

  const doResetIfNotEmpty = useCallback(() => {
    setSelected((prev) => {
      if (!isEmpty(prev)) {
        return {};
      }
      return prev;
    });
  }, [setSelected]);

  const toggleSelect = useCallback(
    (row: SlimRow, source: Source) => {
      setSelected((prev) => {
        if (!isEqual(source, currentType.current)) {
          currentType.current = source;
          const updMap: Record<string, boolean> = { [row.mId]: true };
          if (isStartGroup(row.mId)) {
            for (const r of row.children ?? []) updMap[r.mId] = true;
          }
          return updMap;
        }

        if (prev[row.mId]) {
          const copy = {
            ...prev,
          };

          if (isStartGroup(row.mId)) {
            for (const r of row.children ?? []) {
              delete copy[r.mId];
            }
          }

          delete copy[row.mId];
          return copy;
        }
        return {
          ...prev,
          [row.mId]: true,
        };
      });
    },
    [setSelected, currentType],
  );

  return { toggleSelect, setSelected, doResetIfNotEmpty };
};
