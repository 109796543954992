import { MutableRefObject, useEffect, useMemo, useRef } from 'react';

import useSyncedRef from 'hooks/useSyncedRef';
import { EditorValue } from 'types';
import {
  getDinaSessionIdFromLockedId,
  getScopeFromLockedId,
  getUserIdFromLockedId,
} from 'utils/lock/lockTokenV2';
import { getDinaSessionId } from 'utils/sessionId';

/** The type of the document to be used in the {@link useBeforeUnloadDocument} hook */
export interface BeforeUnloadDocumentBase {
  locked?: string;
}

/**
 * Properties to pass to the function to use when unlocking the member owning the document.
 */
export interface UnlockBeforeUnloadDocumentProps {
  content: EditorValue | null;
  cancelled: boolean;
  source: string;
  forceUnlock?: boolean;
  lockToBeReleased: string;
}

/**
 * First parameter for the {@link useBeforeUnloadDocument} hook
 */
export interface BeforeUnloadDocumentProps<T extends BeforeUnloadDocumentBase> {
  doc: Readonly<T> | null;
  currentUserId: string;
  editorValueRef: Readonly<MutableRefObject<EditorValue | null>>;
  preparedUnlock: (props: UnlockBeforeUnloadDocumentProps) => Promise<string | null | undefined>;
  isLoading: boolean;
  lockedBy: string | null;
  scope: string | undefined;
  saveTimeoutRef: MutableRefObject<ReturnType<typeof setTimeout> | null>;
}

/**
 * Stable functions that provides info on a document
 */
export interface BeforeUnloadDocumentTraits<T extends BeforeUnloadDocumentBase> {
  /**
   * A method that can return a string that identifies the member that owns a document.
   * @param document The document of interest
   * @returns        An ID uniquely identifying a document
   */
  readonly getDocumentId: (document: T) => string;

  /** Gets the type of editor that is used (`'Note'`, `'Script'`, etc) */
  readonly editorType: string;
}

export function useBeforeUnloadDocument<T extends BeforeUnloadDocumentBase>(
  {
    doc,
    currentUserId,
    preparedUnlock,
    isLoading,
    lockedBy,
    scope,
    editorValueRef,
    saveTimeoutRef,
  }: BeforeUnloadDocumentProps<T>,
  { getDocumentId, editorType }: BeforeUnloadDocumentTraits<T>,
) {
  const { isSameScope, lockedUserId, isSameSession } = useMemo(
    () => ({
      isSameScope: scope === getScopeFromLockedId(lockedBy),
      lockedUserId: getUserIdFromLockedId(lockedBy),
      isSameSession: getDinaSessionIdFromLockedId(lockedBy) === getDinaSessionId(currentUserId),
    }),
    [lockedBy, scope, currentUserId],
  );

  const isMyLock = lockedUserId === currentUserId && isSameScope && isSameSession;
  const myLock = isMyLock ? lockedBy : undefined;
  const docId = doc && getDocumentId(doc);
  const myLockedDocId = myLock && doc?.locked === myLock ? docId : undefined;
  const myLastLockedDocIdRef = useRef(myLockedDocId);
  if (myLockedDocId && myLastLockedDocIdRef.current !== myLockedDocId) {
    myLastLockedDocIdRef.current = myLockedDocId;
  }

  /** Contains the ID of the last unlocked document (that has not been relocked afterwards) */
  const myLastUnlockedDocIdRef = useRef<string | null>();
  if (doc && myLastLockedDocIdRef.current === docId && !doc.locked) {
    myLastUnlockedDocIdRef.current = myLastLockedDocIdRef.current;
  } else if (myLockedDocId === myLastUnlockedDocIdRef.current && doc?.locked) {
    myLastUnlockedDocIdRef.current = undefined;
  }

  const unlockRef = useSyncedRef(preparedUnlock);
  const isLoadingRef = useSyncedRef(isLoading);

  useEffect(() => {
    if (!myLock || !docId || scope === undefined) return;
    const lockToBeReleased = myLock;
    const unlockNoteFn = unlockRef.current;
    const mRefId = docId;
    function saveAndUnlock() {
      const alreadyUnlocked = mRefId === myLastUnlockedDocIdRef.current;
      if (alreadyUnlocked || isLoadingRef.current) return;
      /** clear debounce */
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
        saveTimeoutRef.current = null;
      }
      unlockNoteFn({
        content: editorValueRef.current,
        cancelled: false,
        source: `useBeforeUnload${editorType}:saveAndUnlock`,
        forceUnlock: false,
        lockToBeReleased,
      }).catch(() => {});
    }

    window.addEventListener('beforeunload', saveAndUnlock);

    return () => {
      saveAndUnlock();

      window.removeEventListener('beforeunload', saveAndUnlock);
    };
  }, [unlockRef, myLock, docId, scope]);
}
