import { useState } from 'react';
import { useMutation } from '@apollo/client';

import setExtUrls from 'operations/global-functions/utils/setExtUrls';
import DELETE_MEMBER from 'operations/mutations/deleteMember';
import UPDATE_HTMLPANEL from 'operations/mutations/updateHtmlPanel';
import GET_CONFIGS from 'operations/queries/getConfigs';
import { useExternalUrls } from 'store';
import { PanelType } from 'types/members';
import useLogger from 'utils/useLogger';

import { useChangedPanels, useNewPanelId, usePanels } from '../atomsTs';
type QueryType = {
  getMemberFromId: PanelType[];
  updateConfig: PanelType;
  deleteSingleMember: PanelType;
};

export const useUpdatePanels = () => {
  const [changedPanels] = useChangedPanels();
  const [panels, setPanels] = usePanels();

  const [updateHtmlPanel] = useMutation<QueryType>(UPDATE_HTMLPANEL);
  const [, setExternalUrls] = useExternalUrls();
  const [deleteMember] = useMutation<QueryType>(DELETE_MEMBER);
  const [loading, setLoading] = useState(false);
  const removedPanels: PanelType[] = [];
  const logger = useLogger('useUpdatePanels');
  const [, setNewPanelId] = useNewPanelId();
  const updatePanels = async () => {
    setLoading(true);

    for (const changedPanel of changedPanels) {
      if (!panels.find((panel) => panel.mRefId === changedPanel.mRefId)) {
        removedPanels.push(changedPanel);
        continue;
      }
      const { configs, mId, mRefId, mTitle } = changedPanel;
      await updateHtmlPanel({
        variables: {
          input: {
            mTitle,
            mId,
            mRefId,
            configs,
          },
        },
        update: (proxy, { data }) => {
          try {
            const updatedPanel = data?.updateConfig;

            const currentCache = proxy.readQuery<QueryType>({
              query: GET_CONFIGS,
              variables: { input: { mId: 'externalurl' } },
            });

            const exist = currentCache?.getMemberFromId.find(
              (panel) => panel.mRefId === updatedPanel?.mRefId,
            );

            if (!exist) {
              const addedPanels = [
                updatedPanel,
                ...(currentCache?.getMemberFromId || []),
              ] as PanelType[];
              proxy.writeQuery({
                query: GET_CONFIGS,
                variables: { input: { mId: 'externalurl' } },
                data: { getMemberFromId: addedPanels },
              });
              setNewPanelId('');
            } else {
              proxy.writeQuery({
                query: GET_CONFIGS,
                variables: {
                  input: {
                    mId,
                    mRefId,
                  },
                },
                data: { getMemberFromId: [updatedPanel] },
              });
            }
            const cacheAfter = proxy.readQuery<QueryType>({
              query: GET_CONFIGS,
              variables: { input: { mId: 'externalurl' } },
            });
            const getMemberFromId = cacheAfter?.getMemberFromId || [];
            setPanels(getMemberFromId);
            setExtUrls({ data: { getMemberFromId } }, setExternalUrls);
          } catch (error) {
            logger.error('useUpdatePanels error: ', error as Error);
          }
        },
      });
    }

    for (const removedPanel of removedPanels) {
      const { mId, mRefId } = removedPanel;
      await deleteMember({
        variables: {
          input: {
            mId,
            mRefId,
          },
        },
        update: (proxy, { data }) => {
          const deleteSingleMember = data?.deleteSingleMember;
          try {
            const result = proxy.readQuery<QueryType>({
              query: GET_CONFIGS,
              variables: { input: { mId: 'externalurl' } },
            });

            const newCache =
              result?.getMemberFromId?.filter(
                (member) => member.mRefId !== deleteSingleMember?.mRefId,
              ) || [];

            proxy.writeQuery({
              query: GET_CONFIGS,
              variables: { input: { mId: 'externalurl' } },
              data: { getMemberFromId: newCache },
            });

            setPanels(newCache);
            setExtUrls({ data: { getMemberFromId: newCache } }, setExternalUrls);
          } catch (err) {
            logger.error(err as Error);
          }
        },
      });
    }

    setLoading(false);
  };

  return [updatePanels, loading] as const;
};

export default useUpdatePanels;
