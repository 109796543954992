import { ApolloCache, ApolloClient } from '@apollo/client';
import { Theme } from '@emotion/react';
import { isEmpty, keyBy } from 'lodash';
import { nanoid } from 'nanoid';

import GET_RUNDOWN from 'operations/queries/getRundown';
import type { AdjustedRundownType, GetRundownReturnType } from 'screens/rundown/api/useGetRundown';
import { getRundownActiveState } from 'screens/rundown/utils/rundownStates';
import { getRundownPlaybackState } from 'screens/rundown/utils/rundownTypes';
import { TimeStringToDate } from 'screens/rundown/utils/updateDuration';
import { MMetaDataField, TimingValue } from 'types/graphqlTypes';
import { isMemberType } from 'types/utilities';

import { RundownLocation } from '../state/rundown';

import { getTimingsFromMetadata } from './timing';
import {
  type AllGroupInfo,
  type GroupInfo,
  type RundownCellData,
  type RundownSequence,
  type SegmentWithTiming,
  type SlimRow,
  type SpecialRowType,
  typeToDurationProp,
  typeToOrderProp,
} from './types';

const STABLE_T_ARR: Record<string, SegmentWithTiming> = {};
const STABLE_ORDER = [] satisfies string[];

export const getAllInfoFromRundown = (rundown: AdjustedRundownType): AllGroupInfo => {
  try {
    return JSON.parse(rundown.groupInfo ?? '{}') as AllGroupInfo;
  } catch (err) {
    return {};
  }
};

export const generateRundownGroup = (
  rundownId: string,
): { id: string; startId: string; endId: string } => {
  return {
    id: rundownId,
    startId: `-$rundown-${rundownId}`,
    endId: `-#rundown-${rundownId}`,
  };
};

export const generateGroup = (): { id: string; startId: string; endId: string } => {
  const id = nanoid();
  return {
    id,
    startId: `-$${id}`,
    endId: `-#${id}`,
  };
};

/**
 * Denotes the end of a rundown group
 */
export const isEndGroup = (id: string) => {
  return id.startsWith('-#');
};

/**
 * Denotes the start of a rundown group
 */
export const isStartGroup = (id: string) => {
  return id.startsWith('-$');
};

export const getIdFromGroupId = (id: string) => {
  if (isEndGroup(id) || isStartGroup(id)) {
    return id.substring(2);
  }
  return null;
};

export const insertIntoOrder = (order: string[], id: string, index: number): string[] => [
  ...order.slice(0, index),
  id,
  ...order.slice(index),
];

export const getSequenceFromRundown = (
  rundown: AdjustedRundownType,
  type: 'ready' | 'preparing',
): RundownSequence => {
  let groupInfo: Record<string, GroupInfo> = {};
  try {
    groupInfo = JSON.parse(rundown.groupInfo ?? '{}') as Record<string, GroupInfo>;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('parse failed');
  }

  return {
    type,
    groupInfo,
    id: rundown.mId,
    state: getRundownActiveState(rundown.mState),
    playState: getRundownPlaybackState(rundown?.mProperties?.ready),
    isTemplate: rundown.mType === 'rundowntemplate',
    order: rundown[typeToOrderProp[type]] ?? STABLE_ORDER,
    plannedDuration: rundown[typeToDurationProp[type]],
    title: rundown.mTitle ?? '',
    mainRundown: rundown.mSecRefId ?? undefined,
  };
};

const createRowGroup = (
  curId: string,
  curGroupId: string | null,
  isPlaying: boolean,
  isRunning: boolean,
  sequence: RundownSequence,
) => ({
  mId: curId,
  isCurrentPlayingInstance: isPlaying,
  isTemplate: sequence.isTemplate,
  sequenceId: sequence.id,
  sequenceType: sequence.type,
  isRunning,
  // Let end group determine this further down. We only
  // mark a group as run if all instances inside have run
  hasRun: false,
  children: [],
  ...(curGroupId && {
    color: sequence.groupInfo[curGroupId]?.color,
    label: sequence.groupInfo[curGroupId]?.name,
  }),
});

export const getPlaceholderRow = (sequence?: RundownSequence) => ({
  mId: '-',
  sequenceType: sequence?.type ?? 'ready',
  sequenceId: sequence?.id ?? '',
  isTemplate: !!sequence?.isTemplate,
  isCurrentPlayingInstance: false,
  isRunning: false,
  hasRun: false,
});

export const produceRows = (sequence: RundownSequence) => {
  const newOrder: SlimRow[] = [];
  let currGroup: SlimRow | null = null;
  const currentlyPlayingInstance = sequence?.playState?.currentInstance?.mId;
  const isRunning = currentlyPlayingInstance !== undefined;

  let amBeforePlayingInstance = true;
  for (const o of sequence.order) {
    const groupId = getIdFromGroupId(o);
    const isCurrentPlayingInstance = sequence.playState?.currentInstance?.mId === o;
    const hasRun = isCurrentPlayingInstance ? false : amBeforePlayingInstance;
    if (isStartGroup(o)) {
      currGroup = createRowGroup(o, groupId, isCurrentPlayingInstance, isRunning, sequence);
      newOrder.push(currGroup);
    } else {
      if (currGroup) {
        currGroup.children?.push({
          mId: o,
          isCurrentPlayingInstance,
          sequenceId: sequence.id,
          sequenceType: sequence.type,
          isTemplate: sequence.isTemplate,
          isRunning,
          color: currGroup?.color,
          hasRun,
        });
      } else {
        newOrder.push({
          mId: o,
          isCurrentPlayingInstance,
          sequenceId: sequence.id,
          sequenceType: sequence.type,
          isTemplate: sequence.isTemplate,
          isRunning,
          hasRun,
        });
      }

      if (isEndGroup(o) && currGroup) {
        currGroup.hasRun = amBeforePlayingInstance;
        currGroup = null;
      }
    }

    if (isCurrentPlayingInstance) {
      amBeforePlayingInstance = false;
    }
  }

  if (!newOrder.length) {
    return [getPlaceholderRow(sequence)];
  }

  return newOrder;
};

export const getGroupSegments = (members: Record<string, RundownCellData>, rows: SlimRow[]) => {
  if (rows.length === 0) return STABLE_T_ARR;

  const newSegments: SegmentWithTiming[] = [];
  for (const row of rows) {
    if (row.children) {
      const seg: SegmentWithTiming = {
        id: row.mId,
        default_clipDuration: 0,
        default_speakDuration: 0,
        default_totalDuration: 0,
      };
      for (const child of row.children) {
        const data = members[child.mId];
        if (data && !data.isFloated) {
          const timings = getTimingsFromMetadata(data.metadata);
          seg.default_clipDuration += timings.default_clipDuration;
          seg.default_speakDuration += timings.default_speakDuration;
          seg.default_totalDuration += timings.default_totalDuration;
        }
      }
      newSegments.push(seg);
    }
  }
  return keyBy(newSegments, 'id');
};

export const getRundownFromCache = (
  client: ApolloClient<unknown> | ApolloCache<unknown>,
  id: string,
): AdjustedRundownType | null => {
  const { getRundown } =
    client.readQuery<GetRundownReturnType>({
      query: GET_RUNDOWN,
      variables: {
        input: {
          mId: id,
          mRefId: id,
        },
      },
    }) ?? {};
  return getRundown ?? null;
};

/**
 * Special rows are not regular instances, but could be placeholders
 * or markers for start/end for rundown groups.
 */
export const isSpecialRow = (id: string) => {
  return id.startsWith('-');
};

export const isNotSpecialRow = (id: string) => {
  return !id.startsWith('-');
};

export const isGroupRow = (id: string) => {
  return isEndGroup(id) || isStartGroup(id);
};

export const isRundownStartGroup = (id: string) => {
  return id.startsWith('-$rundown-');
};

export const isRundownEndGroup = (id: string) => {
  return id.startsWith('-#rundown-');
};

export const getIdFromRundownGroup = (id: string) => {
  const split = id.split('-$rundown-');
  return split[1];
};

export const isPlaceholder = (id: string) => {
  return !isGroupRow(id) && id.startsWith('-');
};

export const getSpecialRowId = (id: string): SpecialRowType | null => {
  if (isSpecialRow(id)) {
    if (isRundownStartGroup(id)) return 'rundown';
    if (isStartGroup(id)) return 'group_start';
    if (isEndGroup(id)) return 'group_end';
    return 'placeholder';
  }
  return null;
};

export const getEndIndex = (startId: string, order: string[]) => {
  const groupId = getIdFromGroupId(startId);
  for (let i = 0; i <= order.length - 1; i++) {
    const row = order[i];
    if (getIdFromGroupId(row) === groupId) {
      return i;
    }
  }
  return -1;
};

export const getRundownBackgroundColor = (isSub: boolean, isTemplate: boolean, theme: Theme) => {
  if (isSub)
    return isTemplate
      ? theme.palette.dina.paletteAccentDarkOrange
      : theme.palette.dina.paletteAccentDarkRed;
  return isTemplate
    ? theme.palette.dina.paletteAccentOrange
    : theme.palette.dina.paletteAccentPurple;
};

/**
 * For legacy reasons we check whether there's a stringified member type in the value.
 *
 * We now only store the presenters' mId.
 */
export const getHostId = (md?: MMetaDataField[]) => {
  if (!md) return null;
  const field = md.find((m) => m.key === 'host');
  if (!field?.value) return null;
  try {
    const host = JSON.parse(field.value) as unknown;
    if (isMemberType(host)) {
      return host.mId!;
    }
  } catch {
    if (typeof field.value === 'string' && !isEmpty(field.value)) {
      return field.value;
    }
  }
  return null;
};

const isValidTime = (value?: string | null): boolean => {
  // A valid time must be a non-null, non-undefined string that does not start with '-'
  return value != null && !value.startsWith('-');
};

export const getTiming = (value: TimingValue | null) => {
  const timing = value?.value;
  return isValidTime(timing) ? TimeStringToDate(timing!) : undefined;
};

/**
 * Threshold for shifting a time to the next day relative to the rundown start.
 * Times that are more than 18 hours before the start time
 * should be considered as belonging to the next day.
 * Since times are stored in rundown local time (00:00:00 to 24:00:00),
 * only times after 18:00 (6 PM) will be affected.
 */
const nextDayLimitMs = 18 * 60 * 60 * 1000;

const oneDayInMs = 24 * 60 * 60 * 1000;

export const getInstanceRundownTimeV2 = (itime: Date, startTime: string | undefined) => {
  const tInstance = itime.getTime();

  if (!startTime) return tInstance;

  const tStart = new Date(startTime).getTime();

  // If the instance time is on or after the start time, return as-is
  if (tInstance >= tStart) return tInstance;

  // If the instance time is more than the threshold before the start time, move it to the next day
  return tStart - tInstance > nextDayLimitMs ? tInstance + oneDayInMs : tInstance;
};

export const getLocation = (isSubRundown: boolean, loc: RundownLocation): RundownLocation => {
  if (isSubRundown) {
    return loc === 'right' ? 'right-sub' : 'left-sub';
  }
  return loc;
};
