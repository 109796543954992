import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import UPDATE_ORGANIZATION from 'operations/mutations/updateOrganization';
import { useSwitchableOrganizations } from 'store/organization';
import type { Organization, UpdateOrganizationInput } from 'types/graphqlTypes';

interface UpdateOrganization {
  updateOrganization: Organization;
}

function useUpdateOrganization() {
  const [create, { loading, error }] = useMutation<
    UpdateOrganization,
    { input: UpdateOrganizationInput }
  >(UPDATE_ORGANIZATION);
  const [switchableOrganizations, setSwitchableOrganizations] = useSwitchableOrganizations();

  const updateCache = useCallback(
    ({ id, title, description, isArchived, isRestricted, timeZone }: UpdateOrganizationInput) => {
      const org = switchableOrganizations.find((o) => o.id === id);
      if (!org) return;
      if (isArchived || isRestricted) {
        return setSwitchableOrganizations((prev) => prev.filter((o) => o.id !== id));
      }
      setSwitchableOrganizations((prev) =>
        prev.map((o) => {
          if (o.id !== id) return org;
          return { ...org, title, description, timeZone };
        }),
      );
    },
    [switchableOrganizations, setSwitchableOrganizations],
  );

  const updateOrganization = useCallback(
    async (updateOrgProps: UpdateOrganizationInput) => {
      const { id, title, description, isArchived, isRestricted, timeZone } = updateOrgProps;
      const result = await create({
        variables: { input: { id, title, description, isArchived, isRestricted, timeZone } },
        update: () => updateCache(updateOrgProps),
      });
      return result.data?.updateOrganization;
    },
    [create, updateCache],
  );

  return { updateOrganization, loading, error };
}

export default useUpdateOrganization;
