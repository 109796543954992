import { ReactComponent as SagaIcon } from 'assets/images/SagaIcon.svg';
import Text from 'components/text';
import { HStack, VStack } from 'layouts/box/Box';
import URLS from 'utils/constants/urls';

import { Footer, Loading, SagaLogo, Wrapper } from './loading-styled';

export default function LoadingView() {
  return (
    <Wrapper>
      <SagaLogo className="skipOverride" />
      <Loading />
      <Text variant="caption" color="highEmphasis">
        Loading Saga, please wait...
      </Text>
      <Footer>
        <VStack gap="6px">
          <Text variant="captionSmall">
            Copyright © Saga Media Tech AS
            <br />
            All rights reserved.
          </Text>
          <Text
            variant="captionSmall"
            as="a"
            href={URLS.TERMS_CONDITIONS}
            target="_blank"
            rel="noopener"
          >
            Click here for legal notice
          </Text>
        </VStack>
        <HStack gap="4px">
          <SagaIcon />
        </HStack>
      </Footer>
    </Wrapper>
  );
}
