import { memo, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { capitalize } from 'lodash';

import useCommentSubscription from 'features/comments/hooks/useCommentSubscription';
import Script from 'features/script/Script';
import { useSplitViewMolecule } from 'features/splitView/store';
import useCheckUserRight, { useCanSeeOrderManagement } from 'hooks/useCheckUserRight';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { Box } from 'layouts/box/Box';
import {
  TabsContent as RTabsContent,
  TabsList as RTabsList,
  TabsRoot as RTabsRoot,
  TabsTrigger as RTabsTrigger,
} from 'lib/tabs';

import Metadata from './Metadata';
import Planning from './Planning';
import Preview from './Preview';
import Task from './Tasks';
import { sidepanelTabs, SidepanelTabValue } from './types';

export const TabsRoot = styled(RTabsRoot)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabsList = styled(RTabsList)`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding-right: 4px;
`;

const TabsTrigger = styled(RTabsTrigger)`
  ${({ theme }) => theme.typography.dina.tabLabelSmall}
  height: 40px;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  :hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &[data-state='active'] {
    ${({ theme }) => css`
      color: ${theme.palette.dina.highEmphasis};
      box-shadow: inset 0 -2px 0 0 ${theme.palette.dina.highEmphasis};
    `};
  }
`;

const TabsContent = styled(RTabsContent)`
  position: relative;
  outline: none;
  height: 100%;
  flex-grow: 1;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  overflow: hidden;
  &[data-state='active'] {
    display: flex;
  }
`;

const Text = styled('span')`
  cursor: pointer;
  text-decoration: none;
  user-select: none;
`;

export type SidepanelProps = {
  sidepanelTab?: SidepanelTabValue;
  sidepanelSelectedBlockId?: string;
  sidepanelShowComment?: boolean;
};

export const isValidTab = (value: string): value is SidepanelTabValue =>
  sidepanelTabs.includes(value as SidepanelTabValue);

const getAuthorizedTabs = (
  showPlanning: boolean,
  showTask: boolean,
  resourceType: string,
  resourceDetails?: ResourceDetails,
): { label: string; value: SidepanelTabValue }[] => {
  const baseTabs: SidepanelTabValue[] = [];
  const previewEndpoint = resourceDetails?.resource?.mProperties?.provider?.previewEndpoint;
  const embeddedEndpoint = resourceDetails?.resource?.mProperties?.provider?.embeddedEndpoint;
  const platform = resourceDetails?.resource.mProperties?.platform;

  if (resourceType === 'instance') baseTabs.push('metadata');
  if (showPlanning) baseTabs.push('planning');
  if (showTask) baseTabs.push('tasks');
  if (platform?.toUpperCase() === 'CMS' && (previewEndpoint || embeddedEndpoint))
    baseTabs.push('preview');
  if (platform === 'linear') baseTabs.push('script');

  return baseTabs.map((value) => ({ label: value, value }));
};

interface ContentProps {
  tabValue: SidepanelTabValue;
  resourceDetails: ResourceDetails;
  updatePane: () => void;
}
function Content({ tabValue, resourceDetails, updatePane }: Readonly<ContentProps>) {
  switch (tabValue) {
    case 'planning':
      return <Planning resourceDetails={resourceDetails} updatePane={updatePane} />;
    case 'tasks':
      return <Task resourceDetails={resourceDetails} updatePane={updatePane} />;
    case 'metadata':
      return <Metadata />;
    case 'preview':
      return <Preview resourceDetails={resourceDetails} />;
    case 'script':
      return <Script resourceDetails={resourceDetails} />;
    default:
      return null;
  }
}

interface Props {
  resourceDetails: ResourceDetails;
  updatePane?: () => void;
}

function SidePanel({ resourceDetails, updatePane }: Readonly<Props>) {
  const [checkUserRight] = useCheckUserRight();
  const { canAccessOrderManagement } = useCanSeeOrderManagement();
  const canShowMdfBlocks = checkUserRight('feature', 'mdfBlocks');
  const { useSidepanelTab, useForceOpenId, useShowComment } = useSplitViewMolecule();
  const [sidepanelTab, setSidepanelTab] = useSidepanelTab();
  const [, setForceOpenId] = useForceOpenId();
  const [, setShowComment] = useShowComment();

  useCommentSubscription([resourceDetails.resource.mId]);

  const authorizedTabs = useMemo(
    () =>
      getAuthorizedTabs(
        canShowMdfBlocks,
        canAccessOrderManagement,
        resourceDetails.resource.mType,
        resourceDetails,
      ),
    [canShowMdfBlocks, canAccessOrderManagement, resourceDetails],
  );

  const onTabChange = useCallback(
    (tabValue: string) => {
      if (isValidTab(tabValue)) setSidepanelTab(tabValue);
      setForceOpenId(undefined);
    },
    [setSidepanelTab, setForceOpenId],
  );

  const tabRootValue =
    authorizedTabs.find((aTab) => aTab.value === sidepanelTab)?.value ?? authorizedTabs[0]?.value;

  const doUpdatePane = useCallback(() => {
    if (updatePane) {
      updatePane();
    }
    setShowComment(false);
  }, [setShowComment, updatePane]);

  return (
    <TabsRoot onValueChange={onTabChange} value={tabRootValue}>
      <TabsList>
        {authorizedTabs.map((tab) => (
          <TabsTrigger key={tab.label} value={tab.value} asChild>
            <Text>{capitalize(tab.label)}</Text>
          </TabsTrigger>
        ))}
      </TabsList>
      <Box height="100%" width="100%">
        {authorizedTabs.map((tab) => (
          <TabsContent key={tab.value} value={tab.value}>
            <Content
              tabValue={tab.value}
              resourceDetails={resourceDetails}
              updatePane={doUpdatePane}
            />
          </TabsContent>
        ))}
      </Box>
    </TabsRoot>
  );
}

export default memo(SidePanel);
