/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-base-to-string */
import { Component, ErrorInfo, ReactNode } from 'react';
import { ApolloClient } from '@apollo/client';

import { ReactComponent as ArrowDownSmall } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRightSmall } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { Button } from 'components/buttons';
import Collapsible from 'components/collapsible/Collapsible';
import ResizableBox from 'components/resizableBox';
import Scrollbar from 'components/scrollbar';
import Text from 'components/text/Text';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { BOUNDS, ENABLE_RESIZING } from 'utils/constants/resizableBox';
import { auditFailure } from 'utils/sendErrorLogToBackend';

import RefreshButton from './RefreshButton';

import { Caption, Container, HDivider, Wrapper } from './styled';

interface CustomError extends Error {
  code?: string | number;
}

type Props = {
  children: ReactNode;
  apolloClient: ApolloClient<object>;
};

type States = {
  error: CustomError | null;
  errorInfo: ErrorInfo | null;
  timeStamp: Date | null;
  isCollapsed: boolean;
  copy: string;
};

const getErrorMessage = (error: CustomError | null): string => {
  if (!error) return 'Unknown error';
  if (error.message) return error.message;
  return error.toString();
};

class ErrorBoundary extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      timeStamp: null,
      isCollapsed: true,
      copy: 'Copy',
    };
  }

  handleCopy = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    const errorText =
      this.state.error && this.state.errorInfo
        ? `${getErrorMessage(this.state.error)}\n${this.state.errorInfo.componentStack}`
        : '';

    void navigator.clipboard
      .writeText(errorText)
      .then(() => {
        this.setState({ copy: 'Copied!' });
        setTimeout(() => this.setState({ copy: 'Copy' }), 2000);
      })
      .catch(console.error);
  };

  handleRefresh = (): void => {
    window.location.reload();
  };

  handleRestart = (): void => {
    void this.props.apolloClient
      .clearStore()
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        console.error('Failed to clear Apollo store:', e);
        window.location.reload();
      });
  };

  async componentDidCatch(error: CustomError, errorInfo: ErrorInfo) {
    const isDevEnv = import.meta.env.MODE === 'development';
    const timeStamp = new Date();

    this.setState({
      error,
      errorInfo,
      timeStamp,
    });

    if (!isDevEnv) {
      try {
        const errorData = {
          message: error.message || error.toString(),
          code: error.code,
          stack: error.stack,
          componentStack: errorInfo.componentStack,
          origin: 'ux',
        };

        await auditFailure(this.props.apolloClient, errorData, timeStamp);
      } catch (loggingError) {
        console.error('Failed to log error:', loggingError);
      }
    }
  }

  render() {
    const { error, errorInfo, timeStamp, isCollapsed } = this.state;
    const isDevEnv = import.meta.env.MODE === 'development';
    const dinaVersion = (import.meta.env.REACT_APP_VERSION ?? 'v0.0.0') as string;
    const dinaVersionDate = (import.meta.env.REACT_APP_VERSION_DATE ??
      new Date().toISOString()) as string;

    if (errorInfo) {
      return (
        <Container width="100vw" height="100vh">
          <VStack width="600px" gap="8px">
            <Text variant="h4">Something went wrong on our end, we are sorry about that.</Text>
            <Text variant="body2">
              We have already logged the error, and will fix it as soon as possible.
            </Text>
            <HDivider />
            <Text variant="h6">In the meantime, you can try two things:</Text>
            <HStack width="100%" gap="16px" alignItems="flex-start">
              <RefreshButton
                label="Refresh page, and try again"
                onClick={this.handleRefresh}
                caption1="Will reload the page."
                caption2="You can pick up where you left off."
              />
              <Text variant="listItemLabelItalic" style={{ marginTop: 12 }}>
                ...or
              </Text>
              <RefreshButton
                label="Restart Saga"
                onClick={this.handleRestart}
                caption1="Will start a new session of Saga."
                caption2="You need to find and open your work again."
              />
            </HStack>
            <HDivider />
            <Text variant="listItemLabelMedium">Technical information (already logged):</Text>
            <VStack width="100%">
              <Caption>Saga Version: {dinaVersion}</Caption>
              <Caption>Saga Version Date: {dinaVersionDate}</Caption>
              <Caption>Time of incident: {timeStamp?.toString()}</Caption>
              <Caption>Time of incident (ISO): {timeStamp?.toISOString()}</Caption>
              <Caption>Error message: {error?.toString()}</Caption>
            </VStack>
            {isDevEnv && error && (
              <Box width="600px" margin="12px 0 0">
                <Collapsible
                  open={!isCollapsed}
                  setOpen={() => this.setState({ isCollapsed: !isCollapsed })}
                  trigger={
                    <HStack style={{ cursor: 'pointer', marginBottom: 8 }}>
                      {isCollapsed ? <ArrowRightSmall /> : <ArrowDownSmall />}
                      <Text variant="listItemLabelBold" style={{ flex: 1 }}>
                        Stack Trace
                      </Text>
                      <Button
                        height={24}
                        width={80}
                        usage="outlined"
                        variant="outlined"
                        onClick={this.handleCopy}
                      >
                        {this.state.copy}
                      </Button>
                    </HStack>
                  }
                  content={
                    <ResizableBox
                      initialSize={{ width: '600px', height: 300 }}
                      enableResizing={ENABLE_RESIZING}
                      minHeight={200}
                      bounds={BOUNDS}
                      onResize={() => {}}
                    >
                      <Scrollbar>
                        <Wrapper>
                          {error.toString()}
                          {errorInfo.componentStack}
                        </Wrapper>
                      </Scrollbar>
                    </ResizableBox>
                  }
                />
              </Box>
            )}
          </VStack>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
