import { useMemo, useCallback, useRef } from 'react';
import { ScopeProvider } from 'jotai-molecules';
import DatePicker from '../../stories/datePicker';
import MasterRundowns from './master';
import DailyRundowns from './daily';
import { ReactComponent as Plus } from 'assets/icons/systemicons/plus_small.svg';
import groupImage from 'assets/images/notifications/rundownGroups/groupsNotification.jpg';
import masterGif from 'assets/images/notifications/rundownGroups/masterNotification.gif';
import { Button } from 'components/buttons';
import { kanbanScope } from 'features/kanban/store/kanban';
import Scrollbar from 'screens/main/components/scrollbar';
import Text from 'components/text/Text';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import EllipsisDropdown from './EllipsisDropdown';

import { DailyHeader, Row } from './styled';
import OrganizationBanner from './OrganizationBanner';
import { useRundownOrgId } from './store';
import { useSelectedOrgId } from 'store';
import useCheckUserRight from 'hooks/useCheckUserRight';

const RundownLists = ({
  onDateChanged,
  selectedDates,
  setPreviewRundown,
  hideMasterRundowns,
  canCreateNewRundown,
  showMasterRundown,
  setShowMasterRundown,
}) => {
  const [orgId] = useRundownOrgId();
  const [currentOrgId] = useSelectedOrgId();

  const [checkUserRight] = useCheckUserRight();
  const canAccessOrganization = checkUserRight('feature', 'organization');

  const disabled = canAccessOrganization && currentOrgId ? orgId !== currentOrgId : false;

  const ref = useRef();
  const notificationRef = useRef();

  const toggleMasterRundown = useCallback(() => {
    setShowMasterRundown((prev) => !prev);
  }, []);

  const memoizedDatePicker = useMemo(
    () => (
      <DatePicker
        selectedDate={selectedDates}
        onSelectedDateChange={onDateChanged}
        canSelectRange
        showQuickSelect
        enableMultiselect
        hideDropdown
      />
    ),
    [selectedDates],
  );

  return (
    <>
      {!hideMasterRundowns && showMasterRundown && (
        <ScopeProvider scope={kanbanScope} value="masterOrder">
          <div style={{ position: 'absolute', right: 0, top: 40 }} ref={notificationRef} />
          <MasterRundowns
            toggleMasterRundown={toggleMasterRundown}
            setPreviewRundown={setPreviewRundown}
          />
          <NotificationPopup
            position="right"
            imageUrl={masterGif}
            anchor={notificationRef}
            id={notificationIdentifiers.RundownGroupingMaster}
            title="Group and reorder rundowns"
            text={
              <div>
                <div>
                  Create new groups, and organize your rundowns by dragging them into the groups.
                </div>
                <br />
                <div>
                  Double click a group header to edit the group title, and click on the circle to
                  change the color before the label. It is also possible to reorder groups by
                  dragging the group headers.
                </div>
                <br />
                <div>
                  Rundowns without a group will by default be shown as part of the "Uncategorized"
                  group.
                </div>
              </div>
            }
          />
        </ScopeProvider>
      )}
      {!showMasterRundown && (
        <ScopeProvider scope={kanbanScope} value="rundownOrder">
          <DailyHeader>
            <Row>
              <Text variant="h7" color="highEmphasis">
                Rundowns
              </Text>
              {!hideMasterRundowns && (
                <div ref={notificationRef}>
                  <EllipsisDropdown
                    toggleMasterRundown={toggleMasterRundown}
                    isMasterRundown={false}
                  />
                </div>
              )}
            </Row>

            <Row>
              {memoizedDatePicker}
              {canCreateNewRundown && (
                <Button
                  align="left"
                  variant="outlined"
                  usage="outlined"
                  height={32}
                  width={95}
                  startIcon={<Plus />}
                  onClick={(e) => ref.current.create(e)}
                  disabled={disabled}
                  title={
                    disabled
                      ? 'You can only create rundown in active organization'
                      : 'Create new Rundown'
                  }
                >
                  New
                </Button>
              )}
            </Row>
          </DailyHeader>
          <OrganizationBanner isTemplate={false} />
          <Scrollbar>
            <DailyRundowns
              ref={ref}
              selectedDates={selectedDates}
              setPreviewRundown={setPreviewRundown}
              canCreateNewRundown={canCreateNewRundown}
            />
          </Scrollbar>
          <NotificationPopup
            position="right"
            imageUrl={groupImage}
            anchor={notificationRef}
            id={notificationIdentifiers.RundownGrouping}
            title="Rundowns can now be organized into groups"
            text={
              <>
                <div>Administrators can now organize rundowns into separate groups.</div>
                <br />
                <div>
                  This can be done from the new rundown templates view that can be accessed from the
                  ellipsis menu.
                </div>
                <br />
                <div>Note that rundown templates are no longer shown below the daily rundowns.</div>
              </>
            }
          />
        </ScopeProvider>
      )}
    </>
  );
};

RundownLists.defaultProps = {
  onDateChanged: () => {},
  setPreviewRundown: () => {},
  selectedDates: { startDate: null, endDate: null },
  hideMasterRundowns: false,
  canCreateNewRundown: false,
};

export default RundownLists;
