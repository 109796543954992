import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { List as MUIList } from '@material-ui/core';

export const Wrapper = styled('div')`
  width: 320px;
  height: 65vh;
  display: flex;
  justify-content: center;
`;

export const List = styled(MUIList)`
  height: 100%;
`;

export const EmptyList = styled('div')`
  ${({ theme }) =>
    css`
      ${theme.typography.dina.body2}
      margin-top: 16px;
    `}
`;
