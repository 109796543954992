import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { useCreateChannel } from 'api/providers/useCreateChannel';
import { useGetProvider, useGetProviderConfig } from 'api/providers/useGetChannelProvider';
import { ReactComponent as Slack } from 'assets/icons/systemicons/slack.svg';
import { Button } from 'components/buttons';
import { ConfirmDialog } from 'components/dialogs/CommonDialogs';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip';
import { Box, Flex, VStack } from 'layouts/box/Box';
import { useCurrentTabValue } from 'store/tabs';
import transientOptions from 'theme/helpers';
import conversationTypes from 'utils/constants/conversationTypes';

import Messages from './messages/Messages';

const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  max-height: 300px;
`;

const StyledCreateChannel = styled(Box, transientOptions)<{ $disabled: boolean }>`
  height: 100%;
  width: 100px;
  cursor: pointer;
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.palette.dina.disabled : theme.palette.dina.mediumEmphasis};
  &:hover {
    color: ${({ theme, $disabled }) =>
      $disabled ? theme.palette.dina.disabled : theme.palette.dina.highEmphasis};
  }
`;

const StyledSlackIcon = styled(Slack)`
  width: 24px;
`;

const StyledButton = styled(Button)`
  max-height: 32px;
  max-width: 120px;
  text-decoration: none;
`;

const ChatContainer = () => {
  const { providerConfig } = useGetProviderConfig();
  const [polling, setPolling] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const { errorToast } = useToast();
  const currentTab = useCurrentTabValue();
  const { id, title, type } = currentTab ?? {};
  const slackEnabled = type === 'story' && providerConfig !== null;
  const { provider, startPolling, stopPolling } = useGetProvider(slackEnabled ? id : null);
  const { createChannel, loading } = useCreateChannel();
  const shouldShowMessages = id;

  const slackUrl = provider?.providerDetails?.resourceUrl;
  const slackLoading = loading || polling;

  const { conversationId, conversationType } = (() => {
    if (type !== 'space') return { conversationId: id, conversationType: type };

    if (id?.includes('-team'))
      return { conversationId: id.replace('-team', ''), conversationType: conversationTypes.TEAM };

    if (id?.includes('-department'))
      return {
        conversationId: id.replace('-department', ''),
        conversationType: conversationTypes.DEPARTMENT,
      };

    return { conversationId: id, conversationType: type };
  })();

  const createSlackChannel = useCallback(() => {
    if (id) {
      setConfirmation(false);
      createChannel({ storyId: id })
        .then(() => {
          // Wait for slack URL to become available
          startPolling(1000);
          setPolling(true);
        })
        .catch(errorToast);
    }
  }, [id, createChannel]);

  useEffect(() => {
    if (slackUrl && polling) {
      stopPolling();
      setPolling(false);
    }
  }, [slackUrl]);

  useEffect(() => {
    if (polling) {
      setTimeout(() => {
        stopPolling();
        setPolling((prev) => {
          if (prev) {
            errorToast({}, 'Slack channel could not be created');
          }
          return false;
        });
      }, 30000);
    }
  }, [polling, stopPolling, setPolling]);

  if (!shouldShowMessages)
    return (
      <Flex width="100%" height="100%">
        <Text variant="h6">Nothing to show</Text>
      </Flex>
    );

  return (
    <VStack width="100%" height="100%" justifyContent="flex-start">
      <Flex width="100%" height="40px" padding="0 8px">
        <Text
          variant="h7"
          color="highEmphasis"
          truncate
          style={{ width: '100%', textAlign: 'center' }}
          title={title}
        >
          {title}
        </Text>
        {!slackUrl && slackEnabled && (
          <Tooltip title="Create slack channel">
            <StyledCreateChannel
              container
              gap="6px"
              $disabled={slackLoading}
              onClick={() => setConfirmation(true)}
            >
              {slackLoading ? (
                <LoadingButtonIndicator inline size={16} />
              ) : (
                <StyledSlackIcon className="skipOverride" />
              )}
              {slackLoading ? 'Creating' : 'Create'}
            </StyledCreateChannel>
          </Tooltip>
        )}
      </Flex>
      <Messages
        mId={conversationId}
        convoType={conversationType}
        replacementPanel={
          slackUrl && (
            <StyledBox
              container
              flexDirection="column"
              alignItems="center"
              height="200px"
              gap="10px"
              padding="10px"
            >
              <Slack className="skipOverride" />
              <Text variant="body2">A slack channel has been created for this story.</Text>
              <a
                href={slackUrl}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <StyledButton>Open in Slack</StyledButton>
              </a>
            </StyledBox>
          )
        }
      />
      <ConfirmDialog
        open={confirmation}
        onClose={() => setConfirmation(false)}
        onClick={createSlackChannel}
        title="Create slack channel"
        message="Are you sure you want to create a Slack channel for this story? 
        Note that this will disable the Saga chat, but old messages will still be available."
      />
    </VStack>
  );
};

export default ChatContainer;
