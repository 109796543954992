import { useCallback } from 'react';

import { UnlockBeforeUnloadDocumentProps } from './useBeforeUnloadDocument';

export default function usePreparedUnlock(
  prepareUnlockOnSave: (lock: string) => void,
  unlock: (
    props: Omit<UnlockBeforeUnloadDocumentProps, 'lockToBeReleased'>,
  ) => Promise<string | null | undefined>,
): (props: UnlockBeforeUnloadDocumentProps) => Promise<string | null | undefined> {
  return useCallback(
    ({ lockToBeReleased, ...props }: UnlockBeforeUnloadDocumentProps) => {
      prepareUnlockOnSave(lockToBeReleased);
      return unlock(props);
    },
    [prepareUnlockOnSave, unlock],
  );
}
