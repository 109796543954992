import { memo, useCallback, useEffect } from 'react';
import { FetchPolicy } from '@apollo/client';

import { Button } from 'components/buttons';
import Text from 'components/text/Text';
import Kanban from 'features/kanban';
import useGetKanban from 'features/kanban/api/useGetKanban';
import useStoreKanbanUpdates from 'features/kanban/api/useUpdateKanban';
import ReadOnlyKanban from 'features/kanban/ReadOnly';
import { useKanbanMolecule } from 'features/kanban/store/kanban';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { Center, HStack } from 'layouts/box/Box';
import Scrollbar from 'screens/main/components/scrollbar';
import { useSelectedOrgId } from 'store';
import { useUpdatedKanbanBoardForInstanceFilter } from 'store/story';
import { MemberType } from 'types/graphqlTypes';

import EllipsisDropdown from '../EllipsisDropdown';
import OrganizationBanner from '../OrganizationBanner';
import { useRundownOrgId } from '../store';

import { MasterFirstRow, MasterHeader, MasterSecondRow } from './styled';

interface Props {
  mId: string;
  members: MemberType[];
  customRenderItem?: () => void;
  createMasterRundown: () => void;
  toggleMasterRundown: () => void;
}

interface GetKanbanProps {
  getKanban: ({
    mId,
    members,
    fetchPolicy,
  }: {
    mId: string;
    members: MemberType[];
    fetchPolicy: FetchPolicy;
  }) => void;
  loading: boolean;
}

const KanbanLoader = ({
  mId,
  members,
  customRenderItem,
  createMasterRundown,
  toggleMasterRundown,
}: Props) => {
  const { getKanban, loading }: GetKanbanProps = useGetKanban();

  const { useCreateKanbanLane, kanbanBoardUpdatedListener } = useKanbanMolecule();
  const createKanbanLane = useCreateKanbanLane();
  const [useUpdateListener] = kanbanBoardUpdatedListener();
  const { updateKanbanBoard } = useStoreKanbanUpdates();
  const [orgId] = useRundownOrgId();
  const [currentOrgId] = useSelectedOrgId();

  const [checkUserRight] = useCheckUserRight();
  const canAccessOrganization = checkUserRight('feature', 'organization');

  const disabled = canAccessOrganization && currentOrgId ? orgId !== currentOrgId : false;

  const [, setUpdatedKanbanBoardForInstanceFilter] = useUpdatedKanbanBoardForInstanceFilter();

  const onCreateKanbanLane = useCallback(() => {
    createKanbanLane({ mId });
  }, [mId, createKanbanLane]);

  useUpdateListener(
    useCallback(
      (newVal, prevVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(prevVal)) {
          setUpdatedKanbanBoardForInstanceFilter(newVal);
          void updateKanbanBoard({
            kanbanBoard: newVal,
          });
        }
      },
      [mId, updateKanbanBoard, setUpdatedKanbanBoardForInstanceFilter],
    ),
  );

  useEffect(() => {
    if (getKanban) getKanban({ mId, members, fetchPolicy: 'network-only' });
  }, [mId, JSON.stringify(members)]);

  return (
    <>
      <MasterHeader>
        <MasterFirstRow>
          <Text variant="h7" color="highEmphasis">
            Rundown Templates
          </Text>
          <HStack>
            <Button
              variant="discreet"
              usage="outlined"
              height={32}
              width={116}
              onClick={toggleMasterRundown}
            >
              <Text variant="button" color="statusError">
                Exit Templates
              </Text>
            </Button>
            <EllipsisDropdown toggleMasterRundown={toggleMasterRundown} isMasterRundown />
          </HStack>
        </MasterFirstRow>
        <MasterSecondRow>
          <Button
            variant="outlined"
            usage="outlined"
            height={32}
            onClick={onCreateKanbanLane}
            disabled={disabled}
          >
            New Group
          </Button>
          <Button
            variant="outlined"
            usage="outlined"
            height={32}
            onClick={createMasterRundown}
            disabled={disabled}
          >
            New Template
          </Button>
        </MasterSecondRow>
      </MasterHeader>
      <OrganizationBanner isTemplate />
      {members.length === 0 && (
        <Center width="100%" margin="8px 0">
          No rundown templates found
        </Center>
      )}
      {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
         @ts-ignore */}
      <Scrollbar>
        {disabled ? (
          <ReadOnlyKanban
            loading={loading}
            customRenderItem={customRenderItem}
            filterKey="mId"
            darker
            customRenderHeader={undefined}
          />
        ) : (
          <Kanban
            isHorizontal={false}
            kanbanType="rundown"
            loading={loading}
            customRenderItem={customRenderItem}
          />
        )}
      </Scrollbar>
    </>
  );
};

export default memo(KanbanLoader);
