import { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Popover from 'components/shared/popover';
import UserCtx from 'contexts/UserContext';
import useCreateMasterRundown from 'hooks/useCreateMasterRundown';
import useDinaNavigate from 'hooks/useDinaNavigate';
import GET_ALL_RUNDOWN_TEMPLATES from 'operations/queries/getAllRundownTemplates';
import { sortRundownsByStartTime } from 'utils/rundown/sortRundowns';
import useLogger from 'utils/useLogger';

import CreateNewRundown from '../../createNewRundown';
import RundownListItem from '../listItem/RundownListItem';
import { useRundownOrgId } from '../store';

import KanbanLoader from './KanbanLoader';

const Master = ({ setPreviewRundown, toggleMasterRundown }) => {
  const { navigateTo } = useDinaNavigate();
  const user = useContext(UserCtx);
  const { mId: userId } = user;
  const logger = useLogger('leftArea/sidebar/rundown/list/listGroup/group-container');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCreatingAudioRundown, setIsCreatingAudioRundown] = useState(false);
  const [isCreatingTVRundown, setIsCreatingTVRundown] = useState(false);
  const [orgId] = useRundownOrgId();

  const variables = useMemo(() => {
    const v = { mId: '' };
    if (orgId === 'Global') {
      v.globalsOnly = true;
    } else {
      v.globalsOnly = false;
      v.orgId = orgId;
    }
    return v;
  }, [orgId]);

  const [createMasterRundown] = useCreateMasterRundown();

  const { data, error, loading } = useQuery(GET_ALL_RUNDOWN_TEMPLATES, {
    variables,
  });

  const renderItem = useCallback(
    ({ member }) => {
      return (
        <RundownListItem
          rundown={member}
          key={member.mId}
          setPreviewRundown={setPreviewRundown}
          showSelected
        />
      );
    },
    [setPreviewRundown],
  );

  if (error) return <div>{`Error: ${error.message}`}</div>;

  if (loading) return <LoadingIndicator />;

  if (data) {
    const { getAllRundownTemplates } = data;

    const onCreateMasterRundown = async (rundownTitle, platformKind) => {
      const setFunction = !platformKind ? setIsCreatingTVRundown : setIsCreatingAudioRundown;

      setFunction(true);

      try {
        const newRundown = await createMasterRundown(
          rundownTitle,
          platformKind,
          userId,
          orgId === 'Global',
          orgId !== 'Global' ? orgId : undefined,
        );
        setAnchorEl(null);
        setFunction(false);
        if (newRundown) navigateTo(newRundown.mType, newRundown.mId);
      } catch (e) {
        logger.log(e);
      }
      setFunction(false);
    };

    const sortedRundowns = sortRundownsByStartTime([...getAllRundownTemplates]);

    return (
      <>
        <KanbanLoader
          mId={`${orgId === 'Global' ? '' : orgId + '-'}rundowngroups`}
          members={sortedRundowns}
          customRenderItem={renderItem}
          toggleMasterRundown={toggleMasterRundown}
          createMasterRundown={(e) => setAnchorEl(e.currentTarget)}
        />

        <Popover onClose={() => setAnchorEl(null)} anchorEl={anchorEl} position="right">
          <CreateNewRundown
            onCancel={() => setAnchorEl(null)}
            isCreatingAudioRundown={isCreatingAudioRundown}
            isCreatingTVRundown={isCreatingTVRundown}
            onCreate={onCreateMasterRundown}
          />
        </Popover>
      </>
    );
  }

  return null;
};

Master.defaultProps = {
  setPreviewRundown: () => {},
};

export default Master;
