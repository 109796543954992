import { useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { Button } from 'components/buttons';
import Text from 'components/text/Text';
import { AuthContext } from 'contexts/AuthContext';
import { VStack } from 'layouts/box/Box';
import { useForcedLogout } from 'store';
import useLogger from 'utils/useLogger';

const Wrapper = styled(VStack)`
  position: absolute;
  inset: 0;
  backdrop-filter: blur(40px);
  z-index: 1500;
`;

const StyledText = styled(Text)`
  text-align: center;
  width: 100%;
`;
export default function ReloadOnAuthError() {
  const { error } = useLogger('ReloadOnAuthError');
  const [showForcedLogout] = useForcedLogout();
  const { logout } = useContext(AuthContext) as unknown as {
    logout: () => Promise<void>;
  };

  const wrapperRef = useRef(null);

  const loggedOutRef = useRef(false);

  async function handleLogout() {
    try {
      loggedOutRef.current = true;
      await logout();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Logout failed', err);
      if (err instanceof Error) error(err);
    }
  }

  /** To confirm user is logged out properly if they choose to refresh the view
   * instead of clicking
   */
  useEffect(() => {
    const logoutOnUnload = async () => {
      if (!wrapperRef.current || loggedOutRef.current) return;
      await handleLogout();
    };

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('beforeunload', async () => {
      await logoutOnUnload();
    });

    return () => {
      logoutOnUnload()
        .then()
        .catch(() => {});

      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener('beforeunload', async () => {
        await logoutOnUnload();
      });
    };
  }, []);

  return (
    !!showForcedLogout && (
      <Wrapper ref={wrapperRef} gap="16px" alignItems="center">
        <VStack width="100%">
          <StyledText variant="h4">You have been</StyledText>
          <StyledText variant="h4">logged out of Saga</StyledText>
        </VStack>

        <StyledText variant="body2">
          Your session has expired. ({showForcedLogout})
          <br /> Please log in again to continue using Saga.
        </StyledText>

        <Button
          usage="significant"
          width={350}
          onClick={() => {
            handleLogout().catch(() => {});
          }}
        >
          Log in
        </Button>
      </Wrapper>
    )
  );
}
