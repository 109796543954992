import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

export const TextWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  width: 100%;
  > :first-of-type {
    margin-bottom: 4px;
  }
`;

export const StyledCellInput = styled('input')`
  ${({ theme }) => theme.typography.dina.listItemLabelNumber};
  width: 100%;
  height: 100%;
  color: white;
  border: none;
  outline: none;
  background: transparent;
  padding-right: 6px;
  text-align: right;
`;

const getColor = (theme: Theme, $grayOut?: boolean, $orange?: boolean): string => {
  if ($grayOut) return theme.palette.dina.mediumEmphasis;
  if ($orange) return theme.palette.dina.statusWarning;
  return '';
};

export const StyledCellText = styled('div', transientOptions)<{
  $grayOut?: boolean;
  $showError?: boolean;
  $orange?: boolean;
  $hide?: boolean;
}>`
  ${({ $hide }) =>
    $hide &&
    css`
      display: none;
    `};
  width: 100%;
  user-select: none;
  height: 100%;
  padding-right: 6px;
  text-align: right;
  ${({ theme }) => theme.typography.dina.listItemLabelNumber};
  line-height: 30px;
  ${({ theme, $grayOut, $orange }) =>
    css`
      color: ${getColor(theme, $grayOut, $orange)};
      font-weight: ${$orange ? 500 : 'inherit'};
    `}
  ${({ $showError }) =>
    $showError &&
    css`
      box-shadow: inset 0 0 0 1px red;
    `};
`;
