import { useQuery } from '@apollo/client';

import GET_RUNDOWNS from 'operations/queries/getRundowns';
import { Rundown } from 'types/graphqlTypes';

type ReturnType = {
  getRundowns: Rundown[];
};

type InputType = {
  mId: string;
  globalsOnly?: boolean;
  orgId?: string;
};

const useGetMasterRundowns = ({ mId = '', globalsOnly = false, orgId }: InputType) => {
  const variables: InputType = { mId, globalsOnly };

  if (orgId && orgId !== 'Global') variables.orgId = orgId;

  const { data, error, loading } = useQuery<ReturnType, InputType>(GET_RUNDOWNS, {
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  return {
    data: data?.getRundowns,
    error,
    loading,
  };
};

export default useGetMasterRundowns;
