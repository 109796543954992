import { useEffect } from 'react';
import styled from '@emotion/styled';

import Text from 'components/text';
import { Center } from 'layouts/box/Box';
import { useSelectedOrgId, useSwitchableOrganizations } from 'store';
import transientOptions from 'theme/helpers';

import { useRundownOrgId } from './store';

const Wrapper = styled(Center, transientOptions)<{ $isTemplate: boolean }>`
  width: 100%;
  height: 24px;
  border: ${({ theme, $isTemplate }) =>
    `1px solid ${$isTemplate ? theme.palette.dina.statusWarning : theme.palette.dina.info}`};
  background-color: ${({ theme, $isTemplate }) =>
    $isTemplate ? theme.palette.dina.tooltipWarningBackground : theme.palette.dina.audio};
  user-select: none;
`;

const OrganizationBanner = ({ isTemplate = false }: { isTemplate?: boolean }) => {
  const [orgId, setOrgId] = useRundownOrgId();
  const [switchableOrgs] = useSwitchableOrganizations();
  const [selectedOrgId] = useSelectedOrgId();
  const org = switchableOrgs.find((o) => o.id === orgId);

  useEffect(() => {
    if (!orgId) {
      setOrgId(selectedOrgId ?? 'Global');
    } else if (!org) {
      setOrgId('Global');
    }
  }, [orgId, selectedOrgId, setOrgId, org]);

  return (
    <Wrapper $isTemplate={isTemplate}>
      <Text variant="captionMedium" color="highEmphasis">
        {org?.title ?? orgId} Rundown{isTemplate ? ' templates' : 's'}
      </Text>
    </Wrapper>
  );
};

export default OrganizationBanner;
