import { useCallback, useContext } from 'react';
import { ApolloQueryResult } from '@apollo/client';

import UserContext from 'contexts/UserContext';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { useDoubleClickToLockEditor } from 'store';
import { QueryGetMemberArgs } from 'types/graphqlTypes';
import clickIfNothingSelected from 'utils/clickIfNothingSelected';
import { setLockToken } from 'utils/lock/lockTokenV2';

import useCreateScript from '../api/useCreateScript';
import { GetScriptsReturnType } from '../api/useSyncScript';

const useWrapperMouseEvents = ({
  scope,
  resourceDetails,
  shouldCreateScript,
  collaborationEnabled,
  onFocusEditor,
  refetch,
}: {
  scope: string;
  shouldCreateScript: boolean;
  resourceDetails: ResourceDetails;
  collaborationEnabled: boolean;
  onFocusEditor: (exclusive: boolean) => Promise<void>;
  refetch: (
    variables?: Partial<QueryGetMemberArgs> | undefined,
  ) => Promise<ApolloQueryResult<GetScriptsReturnType>>;
}) => {
  const { mId: currentUserId } = useContext(UserContext);

  const { createScript, loading: creating, error: createError } = useCreateScript();

  const [doubleClickToLockEditor] = useDoubleClickToLockEditor();

  const onCreate = useCallback(
    async (exclusive: boolean) => {
      if (!resourceDetails.resource.mId || !resourceDetails.resource.mStoryId) return;

      await createScript({
        mId: resourceDetails.resource.mId,
        mSecId: resourceDetails.resource.mStoryId,
        locked: setLockToken(scope, currentUserId, exclusive ? '' : undefined),
      });
      await refetch();
    },
    [
      createScript,
      currentUserId,
      refetch,
      resourceDetails.resource.mId,
      resourceDetails.resource.mStoryId,
      scope,
    ],
  );

  const onClick = useCallback(
    (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (doubleClickToLockEditor) return;
      const exclusive = !collaborationEnabled || ev.altKey;
      if (shouldCreateScript) {
        onCreate(exclusive).catch(() => {});
        return;
      } else {
        clickIfNothingSelected(ev, () => onFocusEditor(exclusive));
      }
    },
    [doubleClickToLockEditor, onCreate, onFocusEditor, shouldCreateScript],
  );

  const onDoubleClick = useCallback(
    (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!doubleClickToLockEditor) return;
      const exclusive = !collaborationEnabled || ev.altKey;
      if (shouldCreateScript) {
        onCreate(exclusive).catch(() => {});
        return;
      } else {
        onFocusEditor?.(exclusive).then(
          () => {},
          () => {},
        );
      }
    },
    [doubleClickToLockEditor, onCreate, onFocusEditor, shouldCreateScript],
  );

  return { onClick, onDoubleClick, creating, createError };
};

export default useWrapperMouseEvents;
