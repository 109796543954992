import { useCallback, useEffect, useMemo, useState } from 'react';

import { EMPTY_VALUE_FOR_SYNC } from 'components/editor';
import Editor from 'components/editor/Editor';
import { EditorProps } from 'components/editor/types';
import useSyncedRef from 'hooks/useSyncedRef';

import { CollaborativeEditor, CollaborativeEditorProps } from './CollaborativeEditor';
import { CollaborationInfoForEditor } from './types';

export interface CollaborationAwareEditorProps
  extends Omit<EditorProps, 'editorCustomization' | 'readOnly' | 'renderToolbar'> {
  variant: CollaborativeEditorProps['variant'];
  collaborationInfo: CollaborationInfoForEditor;
  /** The document is locked elsewhere */
  readLock: boolean;
  /** The document is locked here (so it is editable) */
  writeLock: boolean;
  /** The document should for some reason not be edited here */
  disableEdit?: boolean;
  reducedHeight?: string;
}

const renderNoToolbar = () => null;

export default function CollaborationAwareEditor(props: Readonly<CollaborationAwareEditorProps>) {
  const { collaborationInfo, writeLock, readLock, onSave, value, disableEdit, ...rest } = props;
  const { collaborating, hasOtherActiveUsers, sharedResourceId, yjsSyncChannel } =
    collaborationInfo;
  const [readLockedReadyResourceId, setReadLockedReadyResourceId] = useState('');
  const readOnly = disableEdit || (!collaborating && !writeLock);
  const useReducedHeight = !readOnly || readLock || hasOtherActiveUsers;
  const height = props.reducedHeight && useReducedHeight ? props.reducedHeight : props.height;
  const renderToolbar = readOnly ? renderNoToolbar : undefined;
  const onReady = useCallback(() => {
    setReadLockedReadyResourceId(sharedResourceId);
  }, [sharedResourceId, setReadLockedReadyResourceId]);
  const onSaveRef = useSyncedRef(onSave);
  const stableOnSave = useCallback(async () => {
    if (onSaveRef.current) await onSaveRef.current();
  }, []);

  const editorValue = !yjsSyncChannel || writeLock ? value : EMPTY_VALUE_FOR_SYNC;
  const stableResourceValue = useMemo(() => editorValue, [sharedResourceId, !yjsSyncChannel]);

  useEffect(() => {
    if (yjsSyncChannel) {
      setReadLockedReadyResourceId('');
    }
  }, [yjsSyncChannel, setReadLockedReadyResourceId]);

  const isReady = readLockedReadyResourceId === sharedResourceId;
  return (
    <>
      {!!yjsSyncChannel && (
        <CollaborativeEditor
          key={sharedResourceId}
          {...rest}
          yjsSyncChannel={yjsSyncChannel}
          readLock={undefined}
          writeLock={!readOnly}
          readOnly={readOnly}
          height={isReady ? height : 0}
          onReady={onReady}
          onSave={stableOnSave}
          renderToolbar={renderToolbar}
          value={stableResourceValue}
        />
      )}
      {(!yjsSyncChannel || !isReady) && (
        <Editor
          {...{
            ...rest,
            writeLock,
            readLock,
            readOnly,
            height,
            value,
            renderToolbar,
            stableOnSave,
          }}
        />
      )}
    </>
  );
}
