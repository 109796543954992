import { memo, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import MessageInput from 'components/messageInput';
import { User } from 'types';
import { EditorValue } from 'types/editor';
import { ConversationTypeEnum } from 'types/graphqlTypes';
import { Conversation } from 'types/messageHub';
import checkIsNotificationConversation from 'utils/messageHub/checkIsNotificationConversation';
import checkIsSystemMessage from 'utils/messageHub/checkIsSystemMessage';
import {
  getInfoFromContent,
  getInfoFromMessage,
  type Info,
} from 'utils/messageHub/getInfoFromContent';
import useLogger from 'utils/useLogger';

import InfoRow from './InfoRow';
import MessageRow from './MessageRow';
import parseContent from './utils';

const MessageBubbleWrapper = styled('div')<{ isSelfMessage: boolean }>`
  padding-left: ${({ isSelfMessage }) => (isSelfMessage ? '88px' : '12px')};
  padding-right: ${({ isSelfMessage }) => (isSelfMessage ? '8px' : '64px')};
  display: flex;
  flex-direction: column;
`;

const MessageEditContainer = styled('div')`
  max-width: 100%;
  padding-left: 4px;
  border-radius: 8px;
  min-width: 28px;
  box-sizing: border-box;
`;

const LastReadLine = styled('div')`
  margin: 20px;
  height: 1px;
  width: calc(100% - 30px);
  position: relative;
  background: ${({ theme }) => theme.palette.dina.statusWarning};
  color: ${({ theme }) => theme.palette.dina.statusWarning};
  &:after {
    content: 'New';
    position: absolute;
    right: -34px;
    top: -9px;
  }
`;

interface Message extends Conversation {
  mContent?: string;
}
interface MessageBubbleProps {
  userId: string;
  message: Message;
  showNewLine?: boolean;
  onDelete: (mRefId: string) => Promise<void>;
  getUser: (userId: string) => User | undefined;
  showEditButton: boolean;
  showDeleteButton: boolean;
  openStory: (info: Info) => void;
  onUpdateMessage: (mRefId: string, mContent: EditorValue) => Promise<void>;
  suggestedUsers: User[];
}

function MessageBubble({
  userId,
  message,
  onDelete,
  getUser,
  showEditButton,
  showDeleteButton,
  openStory,
  onUpdateMessage,
  suggestedUsers,
  showNewLine,
}: Readonly<MessageBubbleProps>) {
  const {
    mRefId = '',
    mCreatedById = '',
    mUpdatedById = '',
    mCreatedAt,
    mUpdatedAt,
    mContent: content = '',
    convoType = '',
    mTitle = '',
  } = message;

  const logger = useLogger('MessageBubble');

  const assignedByUser = getUser(mUpdatedById ?? '');

  const isNotificationMessage = checkIsNotificationConversation(convoType);
  const [isEditingMessage, setIsEditingMessage] = useState<boolean>(false);

  const info = isNotificationMessage
    ? getInfoFromContent(content, convoType, mTitle, assignedByUser?.mTitle)
    : getInfoFromMessage(content, convoType);

  const { mContent, mStoryId = '' } = info;
  const canOpenStory = useMemo(
    () =>
      !!mStoryId ||
      convoType === ConversationTypeEnum.Assignment ||
      convoType === ConversationTypeEnum.Mention,
    [mStoryId, convoType],
  );

  const isSystemMessage = checkIsSystemMessage(message);
  const isSelfMessage = !isNotificationMessage ? userId === (mCreatedById || mUpdatedById) : false;
  const { mTitle: senderName = '', mAvatarKey: senderAvatarKey } =
    isNotificationMessage || isSystemMessage
      ? { mTitle: 'Saga', mAvatarKey: undefined }
      : getUser(mCreatedById ?? mUpdatedById) ?? {};

  const handleDelete = async () => {
    await onDelete(mRefId);
  };

  const handleEdit = () => {
    setIsEditingMessage(true);
  };

  const handleUpdateMessage = async (newContent: EditorValue) => {
    await onUpdateMessage(mRefId, newContent);
    setIsEditingMessage(false);
  };

  const handleCancelEdit = () => {
    setIsEditingMessage(false);
  };

  const handleOpenStory = () => {
    openStory(info);
  };

  const { parsedContent } = parseContent(logger, mContent);

  return isEditingMessage ? (
    <MessageEditContainer>
      <MessageInput
        messageValue={parsedContent}
        handleSaveMessage={handleUpdateMessage}
        handleCancelEdit={handleCancelEdit}
        isEditingMessage={isEditingMessage}
        setIsEditingMessage={setIsEditingMessage}
        showDoneButton={false}
        users={suggestedUsers}
        keepFocus={false}
      />
    </MessageEditContainer>
  ) : (
    <MessageBubbleWrapper isSelfMessage={isSelfMessage} id={mRefId}>
      {showNewLine && <LastReadLine />}
      <MessageRow
        mContent={mContent}
        mAvatarKey={senderAvatarKey}
        mTitle={senderName}
        isSelfMessage={isSelfMessage}
        showEditButton={showEditButton}
        canOpen={canOpenStory}
        handleOpen={handleOpenStory}
      />
      <InfoRow
        onDelete={handleDelete}
        onEdit={handleEdit}
        mCreatedAt={mCreatedAt}
        mUpdatedAt={mUpdatedAt}
        isSelfMessage={isSelfMessage}
        senderName={senderName}
        showDeleteButton={showDeleteButton}
        showEditButton={Boolean(parsedContent) && showEditButton}
        canOpenStory={canOpenStory}
        onOpen={handleOpenStory}
      />
    </MessageBubbleWrapper>
  );
}

export default memo(MessageBubble);
