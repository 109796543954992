import {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useSharedResource from 'hooks/useSharedResource';
import useSyncedRef from 'hooks/useSyncedRef';
import { User } from 'types';
import { CustomChannel, CustomEventListener, JsonSerializable } from 'types/customChannel';
import { createFilteredCustomChannel } from 'utils/filteredCustomChannel';
import { getTimestampFromLockedId, isExclusiveLock } from 'utils/lock/lockTokenV2';

import {
  CollaborationInfoForEditor,
  CollaborationInfoForLockBar,
  CollaborationUsage,
} from './types';

const LOCK_TRANSFER_TIME = 5000;

const RELEASE_LOCK_EVENTS = Object.freeze([
  'lockReleasedOnSave',
  'releaseLockOnCancel',
  'forceUnlock',
]);

/**
 * Holds information about the lock that is released either by force-unlock or cancel.
 */
interface UnlockInfo extends Record<string, JsonSerializable> {
  /** The lock to be released */
  lock: string;
  /** The title of the user that triggered the operation */
  userTitle: string;
}

function isUnlockInfo(x: unknown): x is UnlockInfo {
  return (
    !!x &&
    typeof x === 'object' &&
    'lock' in x &&
    'userTitle' in x &&
    typeof x.lock === 'string' &&
    typeof x.userTitle === 'string'
  );
}

const unlockInfo = (lock: string, user: User | undefined): JsonSerializable => {
  const info: UnlockInfo = {
    lock,
    userTitle: user?.mTitle ?? 'unnamed user',
  };
  return info;
};

const enableLogging = false;
export const tempLog: undefined | typeof console.log = enableLogging
  ? (...args) => console.log('COLLAB:', ...args) // eslint-disable-line no-console
  : undefined;

export interface CollaborationOptions {
  /** The lock used to protect saving */
  lockedBy: string | null;
  writeLock: boolean;
  locking: boolean;
  lockAsync: (timeStamp: string) => Promise<string | null>;
  collaborationFeature?: string;
}

export default function useCollaboration(
  resourceType: string,
  resourceId: string | undefined,
  {
    lockedBy,
    writeLock,
    locking,
    lockAsync,
    collaborationFeature = 'collaborative-editing',
  }: Readonly<CollaborationOptions>,
): CollaborationUsage {
  const userContext = useContext(UserContext);
  const sharedResourceId = resourceId ? `${resourceType}-${resourceId}` : '';
  const [collaborating, setCollaborating] = useState(false);
  const [prevSyncSessionId, setPrevSyncSessionId] = useState<string | null>(null);
  const [releasedLock, setReleasedLock] = useState('');
  const sharedResourceNsChannel = sharedResourceId
    ? `default/${sharedResourceId.replace(/[^a-zA-Z0-9-]/g, '-')}`
    : '';
  const { toast } = useToast();
  const lockedByRef = useSyncedRef(lockedBy);
  const myLock = writeLock ? lockedBy : undefined;
  const writeLockRef = useSyncedRef(writeLock);
  const myConfirmedLock = myLock && !locking ? myLock : undefined;
  useEffect(() => tempLog?.('my lock:', myLock, !locking), [myLock, locking]);
  const sharedResource = useSharedResource(sharedResourceNsChannel, {
    initialState: collaborating,
    myLock: myConfirmedLock,
  });
  const customChannel = sharedResource.customChannel;
  const [checkUserRight] = useCheckUserRight();
  const collaborationEnabled =
    !!sharedResourceNsChannel && checkUserRight('feature', collaborationFeature);
  const collabLock = useMemo(() => {
    if (!collaborationEnabled) {
      return undefined;
    }
    if (myConfirmedLock) {
      return !isExclusiveLock(myConfirmedLock) ? myConfirmedLock : undefined;
    }
    const lockOwner = sharedResource.others.find((p) => isNaN(p.hiddenTime) && !!p.lock);
    return lockOwner?.lock && !isExclusiveLock(lockOwner.lock) ? lockOwner.lock : undefined;
  }, [myConfirmedLock, collaborationEnabled, sharedResource.others]);
  const hasCollaboratingEditors =
    collaborating || sharedResource.others.some((p) => !!p.lock || p.state);
  const syncSessionId = collabLock ? getTimestampFromLockedId(collabLock) : null;
  const stableSyncSessionId = syncSessionId ?? prevSyncSessionId;
  const yjsSyncChannel = useMemo(() => {
    tempLog?.('new filtered channel', stableSyncSessionId, 'at', sharedResourceNsChannel);
    return stableSyncSessionId
      ? createFilteredCustomChannel(customChannel, stableSyncSessionId ?? '')
      : null;
  }, [stableSyncSessionId, customChannel]);

  /**
   * This dictionary contains the channel that should be used to broadcast the `lockReleasedOnSave`
   * event per lock owned or recently owned by me (`myConfirmedLock`).
   * We remember the channel for some time (100ms) in case the lock changes (due to switching
   * context) before the event is to be triggered.
   */
  const customChannelsForUnlockSave = useMemo<Record<string, CustomChannel>>(() => ({}), []);
  const myConfirmedLockRef = useRef<string | undefined>(undefined);
  if (myConfirmedLockRef.current !== myConfirmedLock) {
    if (myConfirmedLock) {
      customChannelsForUnlockSave[myConfirmedLock] = customChannel;
    } else if (myConfirmedLockRef.current) {
      const prev = myConfirmedLockRef.current;
      setTimeout(() => delete customChannelsForUnlockSave[prev], 100);
    }
    myConfirmedLockRef.current = myConfirmedLock;
  }

  const setCollaboratingEtc = useCallback(
    (param: SetStateAction<boolean>) => {
      if (typeof param === 'function') {
        setCollaborating((prev) => {
          const next = param(prev);
          if (next !== prev) sharedResource.updateState(next);
          return next;
        });
      } else {
        sharedResource.updateState(param);
        setCollaborating(param);
      }
    },
    [setCollaborating, sharedResource.updateState],
  );
  const hasOtherActiveUsers = !!sharedResource.others.length;

  const prepareCancel = useCallback(() => {
    if (!lockedByRef.current) return;
    customChannel.broadcastEvent(
      'releaseLockOnCancel',
      unlockInfo(lockedByRef.current, userContext.attributes),
    );
  }, [customChannel, lockedByRef, userContext]);

  const prepareForceUnlock = useCallback(() => {
    if (!lockedByRef.current) return;
    customChannel.broadcastEvent(
      'forceUnlock',
      unlockInfo(lockedByRef.current, userContext.attributes),
    );
  }, [customChannel, lockedByRef, userContext]);

  const prepareUnlockOnSave = useCallback(
    (lock: string) => {
      const channel = customChannelsForUnlockSave[lock];
      if (channel) {
        channel.broadcastEvent('lockReleasedOnSave', lock);
        delete customChannelsForUnlockSave[lock];
      } else {
        tempLog?.('no channel for', lock);
      }
    },
    [customChannelsForUnlockSave],
  );

  useEffect(() => tempLog?.('stable sync session id:', stableSyncSessionId), [stableSyncSessionId]);

  const shouldTakeoverLock =
    !lockedBy &&
    stableSyncSessionId &&
    !syncSessionId &&
    collaborating &&
    !locking &&
    releasedLock &&
    getTimestampFromLockedId(releasedLock) === stableSyncSessionId;
  if (stableSyncSessionId && shouldTakeoverLock) {
    tempLog?.('taking over lock');
    setReleasedLock('');
    lockAsync(stableSyncSessionId)
      .then((lock) => {
        tempLog?.('YJS: locked', lock);
      })
      .catch(() => undefined);
  }

  // This effect is responsible for keeping `prevSyncSessionId` equal to the last non-empty
  // `syncSessionId` for a period of `LOCK_TRANSFER_TIME` if there are still some active editors.
  const isCollaborating = collaborating || hasCollaboratingEditors;
  useEffect(() => {
    if (syncSessionId || !isCollaborating) {
      setPrevSyncSessionId(syncSessionId);
    } else {
      const id = setTimeout(() => setPrevSyncSessionId(null), LOCK_TRANSFER_TIME);
      return () => clearTimeout(id);
    }
  }, [syncSessionId, isCollaborating, setPrevSyncSessionId]);

  // Turn off collaborating if the resource is changed (e.g. when changing daily-note date)
  useEffect(() => {
    tempLog?.('clearing collaboration due to context switch');
    setCollaboratingEtc(false);
  }, [sharedResourceNsChannel, stableSyncSessionId, setCollaboratingEtc]);

  // Turn off collaborating if I am the lock owner (this should not be necessary)
  useEffect(() => {
    if (!myConfirmedLock) return;
    tempLog?.('clearing collaboration due to getting write lock');
    setCollaboratingEtc(false);
  }, [!!myConfirmedLock, setCollaboratingEtc]);

  useEffect(
    () => tempLog?.('channel namespace', sharedResourceNsChannel),
    [sharedResourceNsChannel],
  );

  // This effect is responsible for listening to the 'lockReleasedOnSave' event sent from
  // the lock owner when the lock is released due to a normal save operation,
  // and also to the other released events to turn off collaboration to prevent
  // taking over the lock in force-unlock and cancel cases.
  useEffect(() => {
    const releaseLockHandler: CustomEventListener = (sourceId, type, lockEtc) => {
      tempLog?.('releaseLockHandler', type, lockEtc);
      if (type === 'lockReleasedOnSave') {
        if (typeof lockEtc !== 'string') return;
        setReleasedLock(lockEtc);
        setTimeout(
          () => setReleasedLock((prev) => (prev === lockEtc ? '' : prev)),
          LOCK_TRANSFER_TIME,
        );
      } else if (isUnlockInfo(lockEtc)) {
        const { lock, userTitle } = lockEtc;
        if (lock !== lockedByRef.current) return;
        let collaborationEnded = false;
        setCollaboratingEtc((wasCollaborating) => {
          collaborationEnded = wasCollaborating;
          return false;
        });
        if (collaborationEnded || writeLockRef.current) {
          const forced = type === 'forceUnlock';
          toast({
            title: forced ? 'Force unlock' : 'Cancel',
            description: `${userTitle} ${forced ? 'aborted' : 'cancelled'} ${
              collaborationEnded ? 'collaborative editing session' : 'editing'
            }`,
            type: 'warn',
          });
        }
      }
    };
    const listenerRemovers = RELEASE_LOCK_EVENTS.map((eventName) =>
      customChannel.addEventListener(eventName, releaseLockHandler),
    );
    return () => listenerRemovers.forEach((removeListener) => removeListener());
  }, [customChannel, setReleasedLock]);

  const editorInfo: CollaborationInfoForEditor = useMemo(
    () => ({
      collaborationEnabled,
      collaborating: collaborating && collaborationEnabled,
      hasOtherActiveUsers,
      sharedResourceId,
      yjsSyncChannel,
    }),
    [collaborating, hasOtherActiveUsers, sharedResourceId, yjsSyncChannel, collaborationEnabled],
  );

  const lockBarInfo: CollaborationInfoForLockBar = useMemo(
    () => ({
      others: sharedResource.others,
      collaborating: collaborating && collaborationEnabled,
      setCollaborating: collaborationEnabled ? setCollaboratingEtc : undefined,
      collabLock,
      prepareCancel,
      prepareForceUnlock,
      prepareUnlockOnSave,
    }),
    [collaborating, collaborationEnabled, setCollaboratingEtc, sharedResource.others, collabLock],
  );
  return {
    editorInfo,
    lockBarInfo,
  };
}
